import React from "react";
import PropTypes, { func } from "prop-types";
import { Grid, Typography } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import BuildIcon from "@mui/icons-material/Build";
import ComputerIcon from "@mui/icons-material/Computer";
import BarChartIcon from "@mui/icons-material/BarChart";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloudIcon from "@mui/icons-material/Cloud";
import MeassageIcon from "@mui/icons-material/Message";
import CancelIcon from "@mui/icons-material/Cancel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { withTheme } from "@mui/styles";
import Card from "@mui/material/Card";

const iconSize = 30;

function YoutubeVideo(props) {
    const { theme, blog } = props;

    return (
        <div id="video_section" style={{ backgroundColor: "#000000" }}>
            <div className="container-fluid">
                    <div /*className="video-container"*/>
                    {/* <iframe src="https://www.youtube.com/embed/jsM9orhWw-8" allowFullScreen></iframe> */}
                    <iframe width="980" height="640" src={blog.url} title={blog.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                                                     

                        </div>
            </div>
        </div>
    );
}

YoutubeVideo.propTypes = {
    blog: PropTypes.object.isRequired
};

export default withTheme(YoutubeVideo);
