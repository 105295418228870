import { useEffect, useState } from "react";

// import styles of this component
import styles from "./App.module.css"

// import other components to use
import Header from './Components/Header/Header';
import MasonryLayout from './Components/MasonryLayout/MasonryLayout';
import ContainerCard from './Components/ContainerCard/ContainerCard';
import Dropdown from './Components/Elements/Dropdown/Dropdown';
import API from "./API"
import Prompt from "./Jsons/Prompt.json";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// import json files 
import images from "./Jsons/Images.json"
import { Container } from "@mui/system";
import { Grid, Button, Typography, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Link } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove"

// App component
const Home = () => {
  const [imageArray, setImageArray] = useState([])
  const [currentImageGroup, setCurrentImageGroup] = useState(0)
  const [promptArray, setPromptArray] = useState(Prompt.Prompt)
  const promptIndices = API.getRandomIndices(1, promptArray.length)
  const theme = createTheme({
    palette: {
      mode: 'dark',
    }
  },
  );

  // dropdown items
  const ddItems = [
    {
      id: 1,
      title: "All Images",
      active: true
    },
    {
      id: 2,
      title: "Topic Images",
      active: false
    },
    {
      id: 3,
      title: "Nature Images",
      active: false
    },
    {
      id: 4,
      title: "NFT Images",
      active: false
    },
    {
      id: 5,
      title: "Space Images",
      active: false
    }
  ]

  const [categoryImage, setCategoryImage] = useState(images.categories.all)

  function showMore() {
    var x = document.getElementById("showMore");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    x.scrollIntoView()

  }

  function learnMore() {

  }
  //let imageArray = API.getRandomImages()
  async function getImages() {
    if (currentImageGroup >= promptIndices.length) return    
    let i = promptIndices[currentImageGroup]
    let result = await API.getImagesForPrompt(promptArray[i])
    //console.log(result.images)
    setImageArray(result.images)
    //console.log(imageArray)
    setCurrentImageGroup(currentImageGroup + 1)
  }

  useEffect(() => { 
    if (currentImageGroup < promptIndices.length) {
      //let images = getImages()
      //setImageArray(images)
      getImages()
    }
  }, null)
  
  const takeDdTitle = (ddTitle) => {
    setCategoryImage(() => {
      let categoryChoose = Object.keys(images.categories).filter(item => {
        const titleSplited = ddTitle.toLowerCase().split(" ")[0]
        return item.toLowerCase().includes(titleSplited)
      })
      return [ ...images.categories[categoryChoose] ]
    })
  }

  return (
    <>
      <Header />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div  >
          <Box sx={{ marginLeft: 10, marginBottom: 5 }}>
            <Stack direction="row" alignItems="center" gap={1}>
              <img src="img/aiagent.jpg" width={'64px'} height={'64px'} />
              <Link href="/summer2024"><Typography variant="h4">Summer Coding Challenge</Typography></Link>
            </Stack>
          </Box>

          <Box sx={{ marginLeft: 10, marginBottom: 5 }}>
            <Stack direction="row" alignItems="center" gap={1}>
              <img src="img/openaix100.png" />
              <Typography variant="h4">GPT-4 Turbo Now Supported</Typography>
            </Stack>
            <ListItem>
              <ListItemText>- Knowledge as of April 2023</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>- 128K Text Window</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>- Will be ready for multi-modal</ListItemText>
            </ListItem>
          </Box>
          <Box sx={{ marginLeft: 10, marginBottom: 5 }}>
            <Typography variant="h4" color={'white'} marginTop={10}>
              What's New
            </Typography>
            <Typography variant="h6" marginBottom={5}>Clone Voice, 150 Predefined GPT4 Tasks, SDXL</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <img src="img/ai_voice_clone.jpg" />
              </Grid>
              <Grid item xs={12} md={4}>
                <img src="img/gpt_tasks.jpg" />
              </Grid>          
            <Grid item xs={12} md={4} >
              <img src="img/sdxl_model.jpg" />
              </Grid>
              </Grid>
            {/* <Button variant="contained" href='/aitoolsguide'>Lear More</Button> */}
          </Box>
        </div>

        <div>
          <Box sx={{ marginLeft: 10, marginBottom: 5, marginTop:10 }}>
            <Typography variant="h4" color={'white'}>
             AI Toolbox
            </Typography>
            <Typography variant="h6">Creata Bringing Useful AI Tools to Your Daily Lives:</Typography>
            <Grid container >
              <Grid item>
                <img src="img/creataai_avatar_music_design.png"/>
              </Grid>
            </Grid>
            <Button variant="contained" href='/aitoolsguide'>Learn More</Button> 
            </Box>
      </div>
      <div>
        <Box sx={{marginLeft: 10}}>
          <Typography variant="h4" color={'white'}>
            Generative AI & Controlnet
            </Typography>            
          <Typography variant="h6">iOS & Android App version 3.2 released and available in Apple App Store and Google Play Store. This is a major release with features loaded:</Typography>
            <img src="img/creataai_controlnet_deliberate.png" alt="ai art app"></img>
            <List>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon><RemoveIcon /></ListItemIcon>
                  <ListItemText primary="5 Image-to-image AI models"></ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon><RemoveIcon /></ListItemIcon>
                  <ListItemText primary="24 predefined models for high quality art generation"></ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon><RemoveIcon /></ListItemIcon>
                  <ListItemText primary="Over 600 art styles"></ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon><RemoveIcon /></ListItemIcon>
                  <ListItemText primary="High resolution image enargement and face correction"></ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon><RemoveIcon /></ListItemIcon>
                  <ListItemText primary="GAN AI model for image unblurring"></ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
            <Typography variant="h6">Available now in Apple App Store: <a href='https://apps.apple.com/us/app/creata-ai-art-artist/id1659088194'>Apple App Store</a></Typography>
            <Typography variant="h6">Available now in Google Play Store: <a href='https://play.google.com/store/apps/details?id=com.creataai.creata&hl=en_US&gl=US'>Google Play Store</a></Typography>
            <Button variant="contained" href='/controlnetmodelguide'>Lear More About Converting Photo to Art</Button> 
        </Box>
      </div>
      <div className="flex justify-content-center" style={{ marginTop: "70px", padding: '50px' }}>
        <ContainerCard>
          <Button onClick={() => showMore()} variant="outlined">Learn More About Stable Diffusion</Button>
              <div className={`${styles["gallery-setting"]} flex justify-content-between align-items-center`}>
            <h1>Stable Diffusion Art for Prompt:</h1>
            <div style={{width: '75%'}}><p style={{color: 'white'}}>{promptArray[promptIndices[currentImageGroup-1]]}</p></div>
            {/*<Dropdown title="All Images" items={ddItems} liftingDdTextUp={takeDdTitle} /> */}
          </div>
          {imageArray==undefined || imageArray.length <= 0 ?
            <MasonryLayout images={categoryImage} />
            :
            <MasonryLayout images={imageArray} />
          }
          <Container maxWidth="md" ml="1px">
            <Typography variant='h6' component='p' style={{ display: 'none', color: 'white' }} id='showMore'>
              AI Art is an emerging field that is still in its early stages, but it has already produced some striking and thought-provoking works. One of the key advantages of using AI in art is that it allows for the creation of entirely new and original works that may not have been possible with traditional artistic techniques. For example, machine learning algorithms can be trained on large datasets of images or other forms of data, allowing them to generate new works that are based on patterns and styles that would be difficult for a human artist to replicate.
              <br/><br/>
              Some examples of AI Art include:
              <br /><br />
              "The Next Rembrandt": a project by advertising agency J. Walter Thompson Amsterdam and ING Bank, which used machine learning algorithms to create a new portrait in the style of Dutch master Rembrandt.
              "AICAN": an AI system developed by Rutgers University that uses deep learning algorithms to create original artworks.
              "Adversarial Fashion": a project by artist and researcher Jingyun Zhu that uses machine learning to generate images that are designed to confuse facial recognition systems.
              AI Art raises a range of interesting and complex questions around the role of technology in creative expression, the nature of artistic authorship, and the relationship between human and machine creativity. As AI Art continues to develop and evolve, it is likely to become an increasingly important area of artistic and technological innovation.
              <br/><br/>
              What is Generative AI?<br />
              Generative AI is a type of artificial intelligence that is designed to create new content or output that is similar to what a human might produce. Unlike other types of AI, which are designed to recognize patterns or make decisions based on data, generative AI is focused on creating new and original content.
              <br/>
              Generative AI works by using algorithms and statistical models to analyze and learn from large sets of data, such as text, images, or audio. Once the system has been trained on this data, it can then generate new content that is similar to what it has learned.
              <br/>
              One common use of generative AI is in natural language processing, where the system is trained on large amounts of text to be able to generate new text that is similar in style and tone. This can be used to create chatbots or automated customer service agents that can interact with users in a more human-like way.
              <br/>
              Another use of generative AI is in creating new artwork, music, or other types of creative content. By analyzing patterns and styles from existing works, the system can generate new pieces that are similar in style and tone.
              <br />
              Generative AI has the potential to be a powerful tool for creative industries and can also be used in a variety of other applications, such as creating personalized product recommendations or generating new scientific hypotheses. However, it also raises important ethical and social questions, such as the potential for misuse or bias in the generated content.
              <br />
              AI Art refers to art that is created with the help of artificial intelligence (AI) technology. This can involve a range of techniques, from using machine learning algorithms to generate new images or videos, to using robotics to create physical sculptures or installations.
            </Typography> 
          </Container>
          </ContainerCard>
        </div>
        </ThemeProvider>
    </>
  )
}

export default Home