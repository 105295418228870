import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import env from "react-dotenv";

var stripePrices;
if (process.env.NODE_ENV === 'development') {
  stripePrices = {
    'Creator': {
      'id': 'price_1MobMeLN4oNFaTgWdotC0Lsi',
      'name': 'Creator',
      'price': '3.99',
      'productId': 'prod_NZksL3Mg7yL5Mi'
    },
    'Pro': {
      'id': 'price_1MobRjLN4oNFaTgWyXBTKgpR',
      'name': 'Pro',
      'price': '5.99',
      'productId': 'prod_NZkxOkP1O2nG3B'
    }
  }
} else {
  stripePrices = {
    'Creator': {
      'id': 'price_1MkY7TLN4oNFaTgWs5nTUSlv',
      'name': 'Creator',
      'price': '3.99',
      'productId': 'prod_NVZFr0mMGXQZxJ'
    },
    'Pro': {
      'id': 'price_1Mkt1wLN4oNFaTgWX3FvWTyj',
      'name': 'Pro',
      'price': '5.99',
      'productId': 'prod_NVurKLFkSEpuJC'
    }
  }

}
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Create art on device* or server',
      'Create unlimited images',
      'Discover art prompt',
      'Art Size: 512x512',
    ],
    buttonText: 'Download from Apple App Store',
    buttonVariant: 'outlined',
  },
  {
    title: 'Creator',
    subheader: 'Most popular',
    price: '3.99',
    description: [
      'Create 50*** art images on device* at once',
      'OpenAI model available',
      'Save art locally',
      'Art size: up to 1024x1024',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Pro',
    price: '5.99',
    description: [
      'No NSFW filter**',
      'All fatures from Free App',
      'All feature from Creator subscription',
      'Cancel anytime',
    ],
    buttonText: 'Subscribe now',
    buttonVariant: 'outlined',
  },
];

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

function handleActionButtonClick(buttonText) {
  if (buttonText == 'Free') {
    window.open('https://apps.apple.com/us/app/creata-ai-art-artist/id1659088194', '_blank')
    return
  }
  if (buttonText == 'Creator') {
    //window.open('https://buy.stripe.com/3cs8xG5FN60k9H2000', '_blank')
    //window.open('https://api.creataai.com/checkout.html?pid=price_1MkY7TLN4oNFaTgWs5nTUSlv&name=Creator&price=3.99')
    //window.open(`http://localhost:4000/checkout.html?pid=${stripePrices['Creator'].id}&name=${stripePrices['Creator'].name}&price=${stripePrices['Creator'].price}`)
    let url = `http://api.creataai.com/checkout.html?pid=${stripePrices['Creator'].id}&name=${stripePrices['Creator'].name}&price=${stripePrices['Creator'].price}`
    console.log(url)
    window.open(url)
  } else if (buttonText == 'Pro') {
    let url = `http://api.creataai.com/checkout.html?pid=${stripePrices['Pro'].id}&name=${stripePrices['Pro'].name}&price=${stripePrices['Pro'].price}`
    window.open(url)
    //window.open('https://buy.stripe.com/14k29i5FN0G03iE5kl', '_blank')
    // strip product link here
  }
}
function MacOSVersionTable() {
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Creata AI macOS App Versions
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          These macOS app versions available for download and purchase.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={16}
              sm={tier.title === 'Pro' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Creator' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} onClick={()=>handleActionButtonClick(tier.title)}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
        <Typography variant='h6' component="p" color="text.warning" gutterBottom>
          * For best performance M1 macbook or newer suggested <br/>
          ** Only apply to on-device generated images <br/>
          *** See how 50 images created in one session: <br/>
            <a href='https://youtu.be/mSHRHnnL2YE'><img src='https://creataai.com/img/macos/creata_youtube.jpg'></img></a>
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default function MacOSVersions() {
  return <MacOSVersionTable />;
}
