import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import classNames from "classnames";
import { Typography, Card, Box, Badge,Chip } from "@mui/material";

import withStyles from '@mui/styles/withStyles';
import { light } from "@mui/material/styles/createPalette";

const styles = (theme) => ({
  background: "#f0f0f0",
  img: {
    width: "100%",
    height: "auto",
    marginBottom: 8,
  },
  card: {
    boxShadow: theme.shadows[2],
    background: "#505050"
  },
  noDecoration: {
    textDecoration: "none !important",
  },
  title: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
    "&:active": {
      color: theme.palette.primary.dark,
    },
  },
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  showFocus: {
    "&:focus span": {
      color: theme.palette.secondary.dark,
    },
  },
  mode:
  light
});

function BlogCard(props) {
  const { classes,  displaySize, blog } = props;
  var displaySizeSmall = displaySize
  if (displaySize == undefined) {
    displaySizeSmall = "normal"
  }

  return (
    <Card className={classes.card}>
      <div style={{ position: "relative" }}>
      {blog.img && (
        <Link to={blog.link} tabIndex={-1}>
            <img src={blog.img} className={classes.img} alt="" />
          </Link>
        
        )}
        {displaySizeSmall != "small" && (
          <div style={{ position: 'absolute', right: 20, top: 20 }}>
            <Chip label={blog.type} />
          </div>
        )}
      </div>
      <Box p={2}>
        <Typography variant="body2" color="textSecondary">
          {format(new Date(blog.date * 1000), "PPP", {
            awareOfUnicodeTokens: true,
          })}
        </Typography>
        <Link
          to={blog.link}
          className={classNames(classes.noDecoration, classes.showFocus)}
        >
          <Typography variant="h6">
            <span className={classes.title}>{blog.title}</span>
          </Typography>
        </Link>
        <Typography variant="body1" color="textSecondary">
          {blog.snippet.substr(100)}
          <Link to={blog.link} className={classes.noDecoration} tabIndex={-1}>
            <span className={classes.link}> read more...</span>
          </Link>
        </Typography>
      </Box>
    </Card>
  );
}

BlogCard.propTypes = {
  classes: PropTypes.object.isRequired,
  displaySize: PropTypes.string,
  blog: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BlogCard);
