import React from "react";
import { useForm } from "react-hook-form";
import {
    Typography,
    AppBar,
    Toolbar,
    TextField,
    Button,
    Box,
    Snackbar,
    Alert
} from "@mui/material";
import { Container } from "@mui/system";
import API from "../API"


export default function SignupForm() {    
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [open, setOpen] = React.useState(false);
    const onSubmit = async (data) => {
        console.log(data);
        await API.nsfwSignup(data.email)
        setOpen(true)
    };
    const handleClose = (event, reason) => {
        setOpen(false);
    };

    return (
        <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5">Enter your email address:</Typography>
            <TextField
                style={{ width: "200px", margin: "5px" }}
                label="email"
                type='email' name="email" {...register('email', { required: true })} />
                <Typography variant="h6">{errors?.email && errors.email.message}*This field is required. <a href="https://creataai.com/privacy" target='_blank'> Our Privacy Policy</a></Typography>
            <div margin="20px">
                <Button type="submit" variant="contained" color="primary">Submit</Button>
                </div>
            </form>
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Infomation submit is successful!
                </Alert>
            </Snackbar>
        </Container>
    );
}
