import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';

// import styles of this component
import styles from "./App.module.css"

// import other components to use
import Nav from "./Components/Nav/Nav";
// import something from react packages
import { Icon, SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";
import Prompt from "./Jsons/Prompt.json";
import API from "./API"
import { Grid, Typography, Divider, Link } from "@mui/material";
import Item from "@mui/material/Grid"
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { StarBorderIcon, FavoriteIcon, SubscriptionsRounded } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Container, stepButtonClasses } from "@mui/material";
import AppPlayStore from "./Components/AppPlayStore";
import { MailLockOutline, MailOutline } from "@mui/icons-material";
// import json files 
import images from "./Jsons/Images.json"
import { color } from "@mui/system";


function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const theme = createTheme({
  palette: {
    mode: 'dark',
  }
},
);

// App component
const Support = (props) => {
  const [imageArray, setImageArray] = useState([])
  const [promptText, setPrompt] = useState('')
  const history = useHistory()
  const { prompt} = (props.location && props.location.state) || {};

  async function search() {
    let prompt = document.getElementById("prompt").value
    if(prompt == null || prompt.size <=0) return
    let result = await API.getImagesForPrompt(prompt)
    setImageArray(result.images)
    console.log(result)
  }

  function gotoCreate(promptText) {
    history.push('/create');
  }

  let posts = ["This is the support page"]

  async function searchClick(promptText) {
    document.getElementById("prompt").value = promptText
    let result = await API.getImagesForPrompt(promptText)
    setImageArray(result.images)    
  }
  
  return (
    <>
      <Nav />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Grid item>
              <AppPlayStore />
            </Grid>
            {/*} <Grid sx={{ml: 3}} item><img src='img/create2.jpg' width={257} height={556}></img>
              <img src='img/details.jpg' width={257} height={556}></img></Grid>
            */}
            <Grid
              item
              xs={12}
              md={10}
            >
              <Typography variant="h3" gutterBottom sx={{marginBottom:10}}>
                Support
              </Typography>
              <Typography variant="body2" alignItems="center"><AccountBoxIcon sx={{ verticalAlign: "middle"}}/> applies to subscription only. Not available for free version of Creata Ai app.</Typography>
              <Typography variant="h6"><a href='https://creataai.com/macosapp' style={{ color: 'cyan' }}> 👉 Question Specific for MacOS App</a></Typography>
              <Divider />
              <Grid item sm={12} sx={{ margin: 5 }}>
                <Typography variant="h6" gutterBottom>
                  <Link href='/aitoolsguide'>- AI Tools Guide</Link>
                </Typography>
              </Grid>
              <Grid item sm={12} sx={{ margin: 5 }}>
                <Typography variant="h6" gutterBottom>
                  <Link href='/controlnetmodelguide'>- Controlnet: Image-to-image models and guide</Link>
                </Typography>
              </Grid>
              <Grid item sm={12} sx={{ margin: 5 }}>
                <Typography variant="h6" gutterBottom>
                  <Link href='/blog/ai-music-composer-terms-and-guide'>- AI Music Composer: Terms and Guide</Link>
                </Typography>
              </Grid>
              <Typography variant="h2" sx={{margin:5}}>Q&A</Typography>
              <Typography variant="h6" gutterBottom>
                <Link id="benefits" underline="none">What are the benefits for non-subscriber (free)? </Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                - Create images/art using stable diffusion AI technology <br />
                - Discover others' creation and study their prompts <br />
                - Fast and unmetered generation and no credit required <br />
                - Images generated in a few seconds when server is not busy<br />
                - Use your native language other than English for Prompt description <br />
                - Voice-toImage: describe your image/art using your voice, and no typing <br />                
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="benefitssubscriber" underline="none">What are the benefits for subscriber? </Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                - Faster & reliable image generation <br />
                - More image generation choices including using OpenAI <br />
                - Higher image resolutions (up to 1024x1024) <br />
                - No ads <br />
                - Download and save images <br />
                - Saved history <br />
                Subscribe now to enjoy all the features. Your help keeps us going 🫶.
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="device" underline="none">What are the device type to be supported?</Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                The following devices are (to be) supported:<br />
                - All iPhone models <br/>
                - All iPad models <br/>
                - All android phones <br/>
                - Macbook <br/>
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="batchmode" underline="none">What is Batch Mode <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                <i>Batch Mode</i> allows 1-50 images to be generated on device (your macbook, iPhone etc.) for a given Prompt.
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="batchmodedevice" underline="none">What are the suggested hardware/device for On-Device Image/Art generation?  <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                The suggested device suitable for on-device image generation with estimated time per image generation:<br />
                - Mackbook Pro M1/M2: 16s - 30s per image (same bellow)<br />
                - Mackbook Intel: 3-5 minutes<br />
                - iPhone X and above: 1.5 - 3 minutes<br />
                - iPad Pro: 1 - 2 minutes<br />
                - Regular iPad: not measured <br/>
                - Android Phones: not supported yet <br/>
                - Android Pad: not supported yet <br/>
                The speed is improving with time. We will update the list as we go along.
              </Typography>
              
              <Typography variant="h6" gutterBottom>
                <Link id="usebatchmode" underline="none">How to use batch mode for on device image generation? <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Click on  <img src="img/batchmode.png" width={24} height={45} style={{verticalAlign:"middle"}}/>to navigate to Batch Mode tab in the app.  <br />
                - You will need to download the model file first if it's not downloaded yet. See bellow.<br />
                - Once model file is downloaded and initialized, the app can generate images on your device.<br />
                - Depending on the HW device type, the time needed to generate image varies from 20 second to 3 minutes for Macbook Pro M1 and iPhone.<br/>
                - In Batch Mode, runs in background, and you can always navigate to other tabs and comeback to check the status.
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="download" underline="none">How to download and use model file offline? <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Click on <a href="https://drive.google.com/file/d/1gLQ63iUgiy3BaVDNTunLfOzUgHci5Jk-/view?usp=sharing">this link</a> to download. The file size is ~1.9GB. In the app, to to batch image generation
                page, select the folder icon, then navigate to the folder where you have saved the downloaded zip file. <br/>
                - Example zip file name: bins1.5.bin<br/>
                - Default download location: https://drive.google.com/file/d/1gLQ63iUgiy3BaVDNTunLfOzUgHci5Jk-/view?usp=sharing                
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="modelloadingprocess" underline="none">Why it's slower generating the 1st image and much faster afterwards? <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Before starting generate images, the stable diffusion model needs to be loaded and initialized into memory, that takes about a few minutes. So it takes a little while to have the 1st image generated. Afterwards, it takes ~16-20 seconds to generate one image on Macbook M1 or newer. 
                </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="language" underline="none">How to select language for Prompt? </Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Cilck on the circle with language icon such as 🇺🇸, a popover will show up and select the labuage you want to enter a prompt for image description to be generated. Using your native language, you can better describe the arts you want to generate.
              </Typography> 
              <Typography variant="h6" gutterBottom>
                <Link id="language" underline="none">What are the languages supported by the app? </Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                - English 🇺🇸 <br/>
                - Spanish 🇪🇸<br />
                - French 🇫🇷<br />
                - Korean 🇰🇷<br />
                - Japanese 🇯🇵<br />
                - Chinese Simplified 简<br />
                - Chinese Traditional 繁<br />
              </Typography>
              <Typography variant="h6" gutterBottom>
                <Link id="dictation" underline="none">How do I dictate prompt using voice-to-image? </Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Select the microphone icon 🎙, then press recording button, you can then dictate the image/art you would like to create. Only English is supported for now.
              </Typography>
              <Typography variant="h6" gutterBottom>
                <Link id="image-to-image" underline="none">Do you support Image-to-Image and Dreambox? <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Not in the current release. These festures will be added on in future releases.
              </Typography>

              <Typography variant="h6" gutterBottom>
                <Link id="chatgpt" underline="none">Do you support ChatGPT? <AccountBoxIcon sx={{ verticalAlign: "middle" }} /></Link>
              </Typography>
              <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                Not in the current release. This festure will be added on in future releases.
              </Typography>              
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      {/*  <div className=" justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
          <Container>
              <div className={`${styles["gallery-setting"]} block justify-content-between align-items-center`}>
                <h1>Support</h1>
          </div>
          <div className=" justify-content-center">
            <img src='img/create2.jpg' width={257} height={556}></img>
            <img src='img/details.jpg' width={257} height={556}></img>
            </div>
                <AppPlayStore />
        </Container>
        <div xs={12} md={8} className=" justify-content-center">
          <Typography variant="h5" gutterBottom color={'white'}>Send Us Questions</Typography>
          <Typography variant="h6" gutterBottom color={'white'}><MailOutline />Support Email: <a href="sendTo@creataAi.com@gmail.com">creataAi.com@gmail.com</a></Typography>
          <Typography variant="h5" gutterBottom color={'white'}>FAQ</Typography>
        </div>
  
  </div> */}
        
      

    </>
  )
}

export default Support