import { useState } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";

// import styles of this component
import styles from "./App.module.css"
import theme from "./theme"
import Create from "./Create";
import Explore from "./Explore";
import SwipeableTextMobileStepper from "./ImageCarausel"
import ArtShow from "./ArtShow"
// import other components to use
import Home from './Home';
import Support from './Support';
import nsfw from './nsfw';
import macOSApp from './Pages/macOSApp';
import controlnetModelGuide from './Pages/controlnetModelGuide';
import AIToolsGuide from "./Pages/aiToolsGuide";
import Blogs from "./Components/Blog/Blogs"; 
import GlobalStyles from "./GlobalStyles";
import { blogPosts } from "./BlogContents/blogContents"
import BlogPost from "./Components/Blog/BlogPost"
import PropsRoute from "./Components/PropsRoute";
import Footer from "./Components/Blog/Footer/Footer";
import Feature from "./Feature";
import Developer from "./Developer";
import Summer2024 from "./Summer2024";
//import AIAgent from "./AIAgent";

// App component
const App = () => {
  function getOthers(post) {
    var idx0 = Math.floor(Math.random() * blogPosts.length) - 1

    if ( idx0 <0) idx0 = 0
    var idx1 = idx0 + 5

    if (idx1 >= blogPosts.length) {
      idx1 = blogPosts.length -1
    }
    //console.log(`${idx0} ${idx1}`)

    var others = blogPosts.filter(
      (blogPost) => blogPost.title !== post.title      
    )

    return others.slice(idx0, idx1)
  }
  return (
    <> {
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles/>
      <Router>
        <Switch>
          {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
              {blogPosts.map((post) => (
                <PropsRoute
                  path={post.link}
                  component={BlogPost}
                  title={post.title}
                  key={post.title}
                  date={post.date}
                  weburl={post.weburl}
                  url={post.url}
                  content={post.content}
                  snippet={post.snippet}
                  img={post.img}
                  link={post.link}
                  blog={post}
                  otherArticles={getOthers(post)}  // return 5
                />
              ))}

          <Route path="/" component={Home} exact />
          <Route path="/create" component={Create} />
            <Route path="/feature" component={Feature} />
          <Route path="/explore" component={Explore} />
          <Route path="/imageCarausel" component={SwipeableTextMobileStepper} />
          <Route path="/artShow" component={ArtShow} />
          <Route path="/support" component={Support} />
          <Route path="/nsfw" component={nsfw} />
          <Route path="/macosapp" component={macOSApp} />
          <Route path="/controlnetmodelguide" component={controlnetModelGuide} />
          <Route path="/aitoolsguide" component={AIToolsGuide} />
            <Route path="/blog" component={Blogs} />
              <Route path="/developer" component={Developer} />
              <Route path="/summer2024" component={Summer2024} />
              {/*<Route path="/aiagent" component={AIAgent} /> */}
          <Redirect to="/" />
        </Switch>
          </Router>
          <Footer />
        </ThemeProvider>
        </StyledEngineProvider>
    }
    </>
  );
}

export default App