import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'

// import styles of this component
import styles from "./App.module.css"

// import other components to use
import Header from './Components/Header/Header';
import MasonryLayout from './Components/MasonryLayout/MasonryLayout';
import ContainerCard from './Components/ContainerCard/ContainerCard';
import Dropdown from './Components/Elements/Dropdown/Dropdown';
import Nav from "./Components/Nav/Nav";
// import something from react packages
import { SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";
import Prompt from "./Jsons/Prompt.json";
import API from "./API"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { Divider, Grid, Typography, Card, CardContent, CardHeader, Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Link } from "@mui/material";
import Item from "@mui/material/Grid"

import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite'
import RemoveIcon from '@mui/icons-material/Remove'
import { Container, stepButtonClasses } from "@mui/material";
import AppPlayStore from "./Components/AppPlayStore";
import StarIcon from '@mui/icons-material/StarBorder';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// import json files 
import images from "./Jsons/Images.json"


function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

// App component
const Summer2024 = (props) => {
    const [imageArray, setImageArray] = useState([])
    const [promptText, setPrompt] = useState('')
    const history = useHistory()
    const { prompt } = (props.location && props.location.state) || {};
    const theme = createTheme({
        palette: {
            mode: 'dark',
        }
    },
    );

    async function search() {
        console.log('search')
        let prompt = document.getElementById("prompt").value
        if (prompt == null || prompt.size <= 0) return
        let result = await API.getImagesForPrompt(prompt)
        setImageArray(result.images)
        console.log(result)
    }

    function gotoCreate(promptText) {
        history.push('/create');
    }

    function showMore() {
        var x = document.getElementById("showMore");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
        x.scrollIntoView()

    }
    async function searchClick(promptText) {
        document.getElementById("prompt").value = promptText
        let result = await API.getImagesForPrompt(promptText)
        setImageArray(result.images)
    }

    return (
        <>
            <Nav />
            {/*<Header /> */}
            <ThemeProvider theme={theme}>
                <Container maxWidth="md" sx={{ marginTop: 10 }}>
                    <Card variant="outlined">
                        <CardHeader
                            title='Creata AI App'
                            subheader='iOS, macOS, Android'
                            titleTypographyProps={{ align: 'center' }}
                            action={<StarIcon />}
                            subheaderTypographyProps={{
                                align: 'center',
                            }}
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[400]
                                        : theme.palette.grey[700],
                            }}
                        />
                        <CardContent sx={{
                            backgroundColor: 'black'
                        }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    mb: 2,
                                }}
                            >

                                <AppPlayStore />
                            </Box>
                        </CardContent>
                    </Card>
                </Container>

                <div width="100%" style={{ marginTop: "50px", padding: '50px', backgroundColor:'#1c0301' }}>
                    <Box sx={{ marginLeft: 20, marginBottom: 5}}>
                        <Typography variant="h2" color={'white'} style={{marginBottom:'40px'}}>
                            Summer 2024 Coding Challenges
                        </Typography>

                        <img src="img/aiagent.jpg" style={{ "display": "inline" }} alt="ai agent" width={64} height={64} />

                        <Typography variant="h3" color={'white'}>
                            Build an AI Agent
                        </Typography>
                        
                            <Typography variant="h4" color={'white'} marginBottom={5} marginTop={2}>
                            A simple LLM application which a user can chat with and answers users' questions about movies and TV programs such as:
                            </Typography>
                            <ListItemText marginLeft={2}>- Rating</ListItemText>
                            <ListItemText>- Main story line</ListItemText>
                            <ListItemText>- Main actors</ListItemText>
                        <ListItemText>- Ratings</ListItemText>
                        <ListItemText>- Any other interesting topics</ListItemText>
                        <Typography variant="h5" color={'white'} marginBottom={5} marginTop={2}>
                            Bonus Feature: display cover images and any art of the title when asked by user
                        </Typography>
                    </Box>

                </div>
                <div width="100%" style={{ marginTop: "50px", padding: '50px', backgroundColor: '#032a36' }}>
                    <Box sx={{ marginLeft: 20, marginBottom: 5 }}>
                        <Typography variant="h2" color={'white'} style={{ marginBottom: '40px' }}>
                            Frontend Development Challenge
                        </Typography>
                        <Typography variant="h4" color={'white'} marginBottom={5} marginTop={2}>
                            Based on a given open source fronend repo, build a frontend for AI Agent platform that:
                        </Typography>
                        <ListItemText marginLeft={2}>- Supports light theme</ListItemText>
                        <ListItemText>- Login/Signup</ListItemText>
                        <ListItemText>- Dashboard</ListItemText>
                        <ListItemText>- Anything else you think it should have</ListItemText>
                        Account and anylytic data can be faked. The dashboard layout is up to your taste of design.
                        <Typography variant="h5" color={'white'} marginBottom={5} marginTop={2}>
                            Bonus Feature: use real backend and db for user account and anylytics
                        </Typography>
                    </Box>
                    </div>
                <div width="100%" style={{ marginTop: "50px", padding: '50px', backgroundColor: '#2b1f02' }}>
                    <Box sx={{ marginLeft: 20, marginBottom: 5 }}>
                    <Typography variant="h4" color={'white'} marginBottom={5} marginTop={2}>
                        Q&A
                    </Typography>
                    <ListItemText> Dealine: 2 weeks but flexible</ListItemText>
                    <ListItemText> Due: gitHub repo and a live demo online</ListItemText>
                        <ListItemText> Quality: cool features and not limited by anyone imagination</ListItemText>
                        <ListItemText>Hosting: any platform you want to use. Render.com, netlify.com, vercel.com etc..</ListItemText>
                        <ListItemText>Why are we doing this: recruit the best talent to build next generation AI platform</ListItemText>
                        <ListItemText>Hint: It's not a test but an exercise to show your capability</ListItemText>
                        <ListItemText>Contact: creataai.com@gmail.com</ListItemText>
                    </Box>
                </div>    
                            
                <div style={{ background: '#1d1f21' }}>
                </div>
                {/*<Container maxWidth="100%">
                    {imageArray != undefined || imageArray.length > 0 ?
                        (
                            <ImageList
                                sx={{
                                    //width: '100%',
                                    //height: 450,
                                    // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                                    padding: 20,
                                    transform: 'translateZ(0)',
                                }}
                                rowHeight={512}
                                gap={50}
                            >
                                {imageArray.map((item) => {
                                    const cols = 6; //item.featured ? 2 : 1;
                                    const rows = 2; //item.featured ? 2 : 1;

                                    return (
                                        <ImageListItem key={item.src} cols={cols} rows={rows}>
                                            <img
                                                {...srcset(item.src, item.width, item.height, rows, cols)}
                                                alt={item.prompt}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                sx={{
                                                    background:
                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                    width: '75%',
                                                    margin: 'auto',
                                                    wordWrap: 'breakWord',
                                                    whiteSpace: 'preWrap'
                                                }}
                                                title='creatAI' //{item.model}
                                                subtitle={item.prompt}
                                                position="bottom"
                                                actionIcon={[
                                                    <IconButton
                                                        id='logo'
                                                        sx={{ color: 'white' }}
                                                        aria-label={`favorite ${item.prompt}`}
                                                        onClick={() => searchClick(item.prompt)}
                                                    >
                                                        
                                                        <img src='img/logo.png' id='logoimg' width='40' height='40'></img>
                                                    </IconButton>
                                                ]}
                                                actionPosition="left"
                                            />
                                        </ImageListItem>
                                    );
                                })}
                            </ImageList>
                        )
                        :
                        <div></div>
                    } 
                </Container > 
                */}
            </ThemeProvider>
        </>
    )
}

export default Summer2024