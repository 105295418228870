import { fetch } from "whatwg-fetch";
import Prompt from "./Jsons/Prompt.json";
const urlLocalPath = 'http://localhost:4000'
const urlServerPath = 'https://creataaiAPI.onrender.com' //"https://playablenft.herokuapp.com" limitless-earth-47291.herokuapp.com
var urlPath = urlServerPath  //urlLocalPath

// need to do backend switch based on network
if (process.env.NODE_ENV === "development") { //when doing local testing from localhost
    urlPath = urlLocalPath
}
if (process.env.NODE_ENV === "production") {
    urlPath = "https://creataaiAPI.onrender.com"
}

const token = 'eyJhbGciOiJIUzI1NiJ9.Y3JlYXRhYWljb20.8Dn_HHcDSmy7QfilLXzK7HzbaztMJzcDLieOMdOtWko'
const promptArray = Prompt.Promt

async function getImagesForPrompt(prompt) {
    let data = {
        username: "creataaicom",
        prompt: prompt
    }
        const res = await fetch(
            `${urlPath}/search/prompt`,
            {
                method: 'post',
                mode: 'no-cors',
                headers: {
                    //'token': `${token}`,
                    'Authorization': `token ${token}`,
                   'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
        );
        console.log(`search/prompt:${res}`)
        let result = await res.json()
        return result    
}

async function nsfwSignup(email) {
    let data = {
        username: "creataaicom",
        email: email
    }
    const res = await fetch(
        `${urlPath}/nsfw/signup`,
        {
            method: 'post',
            mode: 'no-cors',
            headers: {
                //'token': `${token}`,
                'Authorization': `token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }
    );
    //console.log(`createLevel:${res}`)
    let result = await res.json()
    return result
}

function getRandomIndex(N) {
    return Math.floor(Math.random() * N);
}

/**
 * 
 * @param {*} n number of indices
 * @param {*} N max value of index
 * @returns array of indices for prompts
 */
function getRandomIndices(n,N) {
    var prompts = []
    var imageArray = []
    for (var i = 0; i < n; i++) {
        prompts.push(getRandomIndex(N))
    }
    return prompts
}

/**
 * Not working
 * @returns 
 */
async function getRandomImages() {
    // select 10 prompt randomly and get 3 images from each prompt
    //let currentIndex = Prompt.length;
    //let promptArray = Prompt.Prompt
    let N = promptArray.length //Prompt.Promt.length


    var prompts = []
    var imageArray = []
    for (var i = 0; i < 10; i++) {
        prompts.push(getRandomIndex(N))
    }
    console.log(prompts)
    console.log(`0: ${prompts[0]}`)

    for (var i = 0; i < 1; i++) {
        var j = prompts[i]
        var promptStr = promptArray[j]
        //var images = await getImagesForPrompt(promptStr)
        getImagesForPrompt(promptStr).then((images) => {
            console.log(images)
            console.log(images[0])
            var n = images.length
            console.log(`${n}`)
            j = getRandomIndex(50)
            console.log(`${j}`)
            imageArray.push(images[j])
            j = getRandomIndex(50)
            console.log(`${j}`)
            imageArray.push(images[j])
        })
    }
    console.log(imageArray)
    return imageArray
}

export default {
    getImagesForPrompt,
    getRandomImages,
    getRandomIndices,
    nsfwSignup
}