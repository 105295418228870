import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'

// import styles of this component
import styles from "./App.module.css"

// import other components to use
import Header from './Components/Header/Header';
import MasonryLayout from './Components/MasonryLayout/MasonryLayout';
import ContainerCard from './Components/ContainerCard/ContainerCard';
import Dropdown from './Components/Elements/Dropdown/Dropdown';
import Nav from "./Components/Nav/Nav";
// import something from react packages
import { SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";
import Prompt from "./Jsons/Prompt.json";
import API from "./API"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { Divider, Grid, Typography, Card, CardContent, CardHeader, Box, Button } from "@mui/material";
import Item from "@mui/material/Grid"

import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Container, stepButtonClasses } from "@mui/material";
import AppPlayStore from "./Components/AppPlayStore";
import StarIcon from '@mui/icons-material/StarBorder';

// import json files 
import images from "./Jsons/Images.json"


function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

// App component
const Explore = (props) => {
  const [imageArray, setImageArray] = useState([])
  const [promptText, setPrompt] = useState('')
  const history = useHistory()
  const { prompt} = (props.location && props.location.state) || {};

  async function search() {
    console.log('search')
    let prompt = document.getElementById("prompt").value
    if(prompt == null || prompt.size <=0) return
    let result = await API.getImagesForPrompt(prompt)
    setImageArray(result.images)
    console.log(result)
  }

  function gotoCreate(promptText) {
    history.push('/create');
  }

  function showMore() {
    var x = document.getElementById("showMore");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    x.scrollIntoView()

  }
  async function searchClick(promptText) {
    document.getElementById("prompt").value = promptText
    let result = await API.getImagesForPrompt(promptText)
    setImageArray(result.images)    
  }
  
  return (
    <>
      <Nav />
      {/*<Header /> */}
      <div className="flex justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
        <Grid container spacing={2} columns={12}>
          <Typography variant="h4" marginLeft={20} component="h4" sx={{color: 'white'}}>
            Discover
          </Typography>
            <Grid item >
              <Item>
                <div className={`${styles["search-bar"]} flex align-items-center`}>
                  <SearchNormal1 size="30" color="var(--white-100)" />
                  <input id="prompt" type="text" className={styles["search-input"]} defaultValue={prompt} placeholder="Enter text to find art created by others" />
                  <button className={`${styles["search-btn"]} flex justify-content-center align-items-center`} onClick={()=>search()}>
                    <Setting4 size="20" color="var(--dark-900)" />
                </button>
              </div>
              <Typography variant="h6" marginTop={2}>Let AI generate detailed prompt for you?</Typography>
              <Button sx={{ marginTop: '20px', color: 'cyan' }} variant=" contained" color="cyan" onClick={() => gotoCreate()}>See How It Works</Button>

              </Item>
          </Grid>
          
        </Grid>
      </div>
      <Container maxWidth="100%">
        {imageArray != undefined || imageArray.length > 0 ?
          (
            <ImageList
              sx={{
                //width: '100%',
                //height: 450,
                // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                padding: 20,
                transform: 'translateZ(0)',
              }}
              rowHeight={512}
              gap={50}
            >
              {imageArray.map((item) => {
                const cols = 6; //item.featured ? 2 : 1;
                const rows = 2; //item.featured ? 2 : 1;

                return (
                  <ImageListItem key={item.src} cols={cols} rows={rows}>
                    <img
                      {...srcset(item.src, item.width, item.height, rows, cols)}
                      alt={item.prompt}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      sx={{
                        background:
                          'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        width: '75%',
                        margin: 'auto',
                        wordWrap: 'breakWord',
                        whiteSpace: 'preWrap'
                      }}
                      title= 'creatAI' //{item.model}
                      subtitle={item.prompt}
                      position="bottom"
                      actionIcon={[
                        <IconButton
                          id='logo'
                          sx={{ color: 'white' }}
                          aria-label={`favorite ${item.prompt}`}
                          onClick={() => searchClick(item.prompt)}
                        >
                          {/*<FavoriteIcon />*/}
                          <img src='img/logo.png'id='logoimg' width='40' height='40'></img>
                        </IconButton>
                      ]}
                      actionPosition="left"
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          )
          :
          <div></div>
        }
      </Container>
      <Container maxWidth="md" ml="1px">
        <Typography variant='h6' component='p' style={{ display: 'none', color: 'white' }} id='showMore'>
          About AI Model - Stable Diffusion<br/>
          Stable diffusion is a mathematical concept that describes the behavior of a system in which a substance or energy spreads out over time. In this context, stability refers to the degree to which the spreading is predictable and consistent.
          <br/><br/>
          The concept of stable diffusion is used in a variety of fields, including physics, chemistry, and engineering, to model the behavior of diffusion processes. In mathematical terms, stable diffusion refers to a type of diffusion process in which the distribution of the substance or energy spreading out remains relatively unchanged over time.
          <br /><br />
          Stable diffusion is characterized by a particular type of distribution, known as a stable distribution, which has a number of important mathematical properties. For example, stable distributions are symmetric and have heavy tails, meaning that they have a higher probability of producing extreme values than other types of distributions.
          <br /><br />
          Stable diffusion has a number of practical applications, including in the study of heat transfer, fluid dynamics, and the spread of pollutants in the environment. By modeling the behavior of diffusion processes, scientists and engineers can better understand how substances and energy spread over time, and can develop more effective strategies for controlling or mitigating their effects.
        </Typography>
        </Container>
      <Divider />
      <Container maxWidth="md">
      <Card variant="outlined">
        <CardHeader
          title='Creata AI App'
          subheader='iOS, macOS, Android'
          titleTypographyProps={{ align: 'center' }}
          action={<StarIcon />}
          subheaderTypographyProps={{
            align: 'center',
          }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[400]
                : theme.palette.grey[700],
          }}
        />
        <CardContent sx={{
          backgroundColor: 'black'
        }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
              mb: 2,
            }}
          >

            <AppPlayStore />
            </Box>
        </CardContent>
      </Card>
</Container>
    </>
  )
}

export default Explore