import { useHistory } from "react-router-dom";
// import styles of this component
import styles from "./MasonryLayout.module.css"

// import other react pkg to use
import Masonry from "react-masonry-css"

// import other component to use
import MasonryBox from './MasonryBox/MasonryBox';

// MasonryLayout Component
const MasonryLayout = ({ images }) => {
  const history = useHistory()

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  function onImageClickedCallback(image) {
    //history.push({ pathname: '/imageCarausel', state: { images: images } })
    history.push({ pathname: '/artShow', state: { images: images } })
  }

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={styles["my-masonry-grid"]}
      columnClassName={styles["my-masonry-grid_column"]}
    >      
      {images.map(item => (
        <MasonryBox 
          key={item.id} 
          src={item.src} 
          prompt={item.prompt} 
          callback={onImageClickedCallback}
        />
      ))}
    </Masonry>
  )
}

export default MasonryLayout