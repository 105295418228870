import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import format from "date-fns/format";
import { Grid, Typography, Card, Box, CardMedia, Chip, Container, Breadcrumbs, Link, List, ListItem } from "@mui/material";
import { withStyles, styled } from '@mui/styles';
import BlogCard from "./BlogCard";
import ShareButton from "../ShareButton";
import ZoomImage from "../ZoomImage";
import smoothScrollTop from "../smoothScrollTop";
import Nav from "../Nav/Nav";
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import CssBaseline from "@mui/material/CssBaseline";
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import YoutubeVideo from '../Elements/YoutubeVideo'
import { blogPosts } from '../../BlogContents/blogContents'

const styles = (theme) => ({
  blogContentWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    maxWidth: 1280,
    width: "100%",
    typography: {
      h3: {
        fontSize: 12,
        marginTop: 10
      },
      h2: {
        fontSize: 12,
        marginTop: 10
      },
    }
  },
  wrapper: {
    minHeight: "60vh",
      //backgroundColor: "#e9eef7", //theme.palette.common.white,
      overflowX: "hidden",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  card: {
    boxShadow: theme.shadows[4],
  },
  typography: {
    h3: {
      fontSize: 12,
      marginTop: 10
    },
    h2: {
      fontSize: 12,
      marginTop: 10
    },
  }
});

const testMDString0 = `
**header**
An h1 header
============

`

const testMDString = ` # Generative AI

A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |

***italic***
`
const markdown = `<h1> Title</h1>
Just a link: https://reactjs.com.`
const drawerWidth = 0
//const testMD = (< MuiMarkdown className={styles.markdown} children={testMDString0} remarkPlugins={[remarkGfm, remarkRehype]} />) //
const testMD = (< MuiMarkdown>{testMDString}</MuiMarkdown>) //
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);


function BlogPost(props) {
  const { classes, blog, otherArticles } = props;

  function getOtherVideos(post) {
    let videos = blogPosts.filter((x) => x.type === 'video'
    )
    console.log(`${videos.length}`)
    let videos1 = videos.filter((x) => x.title != post.title)
    console.log(`${videos1.length}`)

    var idx0 = Math.floor(Math.random() * videos1.length) - 1
    if(idx0<0) idx0 = 0
    
    var idx1 = idx0 + 5
    if (idx1 >= videos1.length) idx1 = videos1.length
    console.log(`${idx0} - ${idx1}`)
    return videos1.slice(idx0,idx1)
  }

  useEffect(() => {
    document.title = `Creata AI - ${blog.title}`;
    smoothScrollTop();
  }, [blog.title]);

  return (
    <>
      <Nav />

      {/*   <Container maxWidth="lg"> */}

      <Container sx={{marginTop: 3}}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="text.primary" href="/blog">
            Blog
          </Link>
        </Breadcrumbs>

      </Container>
    <Box
      className={classNames("lg-p-top", classes.wrapper)}
      display="flex"
      justifyContent="center"
      >

      <div className={classes.blogContentWrapper}>
        <Grid container spacing={5} sx={{height: "100%"}}>
          <Grid item md={10}>
            {/*<iframe width="100%" height="5000" src={weburl} title="athena blog article"></iframe>*/}
            <Card className={classes.card}>
              <Box pt={3} pr={3} pl={3} pb={2}>
                <Typography variant="h4">
                  <b>{blog.title}</b>
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {format(new Date(blog.date * 1000), "PPP", {
                    awareOfUnicodeTokens: true,
                  })}
                </Typography>
                </Box>
                <Box p={3}>
                  {blog.type === 'video' ?
                    <Fragment>
                      <YoutubeVideo blog={blog} />
                      <Typography variant="h3">{blog.snippet.substr(0,100)}</Typography>
                      <Typography variant="h6" sx={{marginTop: 5, marginBottom: 5}}><a href={blog.videoLink}>Source</a></Typography>
                      <Typography variant="h5" sx={{marginBottom: 3}}>Other Videos</Typography>
                      <List>
                        {
                          getOtherVideos(blog).map((x) => (
                            <ListItem><Link href={x.link} variant="h4" underline="none">{x.title}</Link></ListItem>
                          ))
                          
                        }
                      </List>
                    </Fragment>
                    :                    
                      <Fragment>
                  <Card className={classes.root} sx={{paddingBottom: "50px"}}>
                    <div style={{ position: "relative" }}>
                      <CardMedia style={{ height: "450px", paddingTop: "2%" }} component="img" image={blog.img} title={blog.title} alt={blog.title} />
                      <div style={{ position: "absolute", color: "white", bottom: 20, left: "15%", transform: "translateX(-15%)", }}>
                        <Typography variant="h4" sx={{fontWeight: "regular",}}>{blog.title}</Typography>
                      </div>
                      <div style={{ position: "absolute", left: 10, top: 40 }}>
                        <img src="/img/logo.png" width={20} height={20}></img>
                        </div>
                      <div style={{ position: "absolute", right: 20, top: 40 }}>
                        <Chip label={blog.type} />
                      </div>
                    </div>
                  </Card>
                      <Box pt={10}>
                        <Grid spacing={1} container>
                          {["Facebook", "Twitter", "Reddit", "Tumblr"].map(
                            (type, index) => (
                              <Grid item key={index}>
                                <ShareButton
                                  type={type}
                                  title={blog.title}
                                  description={blog.title}
                                  url={blog.url}
                                  disableElevation
                                  variant="contained"
                                  className="text-white"
                                  classes={{
                                    label: "text-white",
                                  }}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Box>

                {blog.content}
                {/*<object data="/blogs/post1.pdf" type="application/pdf" width="100%" height="500px">
                  <p>Unable to display PDF file. <a href={weburl}>Click to Read</a> instead.</p>                
                </object>*/}
                    </Fragment>
              }
              {/*<iframe width="100%" height="100%" src={weburl} title="description"></iframe>*/}
              
                <Box pt={10}>
                  <Grid spacing={1} container>
                    {["Facebook", "Twitter", "Reddit", "Tumblr"].map(
                      (type, index) => (
                        <Grid item key={index}>
                          <ShareButton
                            type={type}
                            title={blog.title}
                            description={blog.snippet}
                            url={blog.url}
                            disableElevation
                            variant="contained"
                            className="text-white"
                            classes={{
                              label: "text-white",
                            }}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Box>
            </Card>
                          
          </Grid>
          <Grid item md={2}>
            <Typography variant="h6" paragraph>
              Other articles
            </Typography>
            {otherArticles.map((blogPost) => (
              <Box key={blogPost.title} mb={3}>
                <BlogCard
                  url={`${blogPost.url}${blogPost.params}`}
                  displaySize="small"
                  blog={blogPost}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </div>
        </Box>
      {/*</Container>*/}
      </>
      );
    
}

BlogPost.propTypes = {
  classes: PropTypes.object.isRequired,
  otherArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
  blog:PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BlogPost);
