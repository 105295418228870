const AppPlayStore = () => {
  // Inline style for the images by default
  const defaultStyle = {
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  };

  // Function to apply hover effect
  const applyHoverEffect = (e) => {
    e.target.style.opacity = "1"; // You can adjust this value if needed
    e.target.style.transform = "scale(1.1)"; // Slightly larger scale for more expandability
    e.target.style.boxShadow = "0px 0px 15px rgba(255, 255, 255, 0.6)"; // Simulate a shiny effect
  };

  // Function to remove hover effect
  const removeHoverEffect = (e) => {
    e.target.style.opacity = ""; // Reset to default by removing the inline style
    e.target.style.transform = ""; // Reset to default by removing the inline style
    e.target.style.boxShadow = ""; // Remove the box-shadow
  };

  return (
    <div
      id="appplaystore"
      style={{ display: "flex", marginTop: "80px", alignItems: "center", width: "45%", justifyContent: "center"}}
    >
      <div
        id="appstore"
        style={{ display: "block",padding: "5px",marginLeft: "0px"}}
      >
        <a href="https://apps.apple.com/us/app/creataai/id1659088194">
          <img
            src="img/appstore_downloadx200.jpeg"
            alt="Download on the App Store"
            width={200}
            height="70"
            style={defaultStyle}
            onMouseEnter={applyHoverEffect}
            onMouseLeave={removeHoverEffect}
          />
        </a>
      </div>
      <div id="playstore" style={{ display: "block", marginLeft: "10px" }}>
        <a href="https://play.google.com/store/apps/details?id=com.creataai.creata&hl=en_US&gl=US">
          <img
            src="img/playstore.png"
            alt="Get it on Google Play"
            width="200"
            height="80"
            style={defaultStyle}
            onMouseEnter={applyHoverEffect}
            onMouseLeave={removeHoverEffect}
          />
        </a>
      </div>
      {/* macOS Div */}
      <div id="macos" style={{ display: "block", marginLeft: "25px" }}>
        <a href="https://creataai.com/macOSApp">
          <h1
            style={{ ...defaultStyle, fontSize: "35px", color: "white", borderRadius: "10px", backgroundColor: "black", border: "1px solid white", width: "200px", height:"55px", textAlign: "center", paddingBottom: "3px"}}
            onMouseEnter={applyHoverEffect}
            onMouseLeave={removeHoverEffect}
          >
            macOS
          </h1>
        </a>
      </div>
    </div>
  );
};

export default AppPlayStore;
