import { Fragment, useEffect, useState } from "react";

// import styles of this component
import styles from "./App.module.css"

// import other components to use
import Header from './Components/Header/Header';
import MasonryLayout from './Components/MasonryLayout/MasonryLayout';
import ContainerCard from './Components/ContainerCard/ContainerCard';
import Dropdown from './Components/Elements/Dropdown/Dropdown';
import Nav from "./Components/Nav/Nav";
// import json files 
import images from "./Jsons/Images.json"
import AppPlayStore from "./Components/AppPlayStore";
import useScript from './hooks/useScript';
import { Button, Typography, Grid,Tabs, Tab } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';


// App component
const Create = () => {
  useScript('https://unpkg.com/@ungap/custom-elements-builtin');
  useScript('https://unpkg.com/x-frame-bypass');
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function test() {
    var xhr = new XMLHttpRequest();

    let url ="https://google-sdxl.hf.space/?__theme=dark"
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return;
      if (xhr.status === 200) {
        var doc = iframe.contentWindow.document;
        doc.open();
        doc.write(xhr.responseText);
        doc.close();
        console.log(doc)
      }
    }
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Origin", "google.com")
    xhr.send(null);    
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
    //test()
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
    }
  },
  );


  useEffect(() => {
    //test()
  },[])
  return (
    <>
      <Nav />
      <ThemeProvider theme={theme}>
      <div className="flex justify-content-center" style={{ marginTop: "1px", padding: '50px' }}>
          <ContainerCard>
          {/*   <div className={`${styles["gallery-setting"]} flex justify-content-between align-items-center`}>
                <h1>Create</h1>
  </div> */}
          <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
            justifyContent="center"
            marginBottom={5}
          >
            <Grid item sm={12}>
              <AppPlayStore />
            </Grid>
            </Grid>
          <Grid item xs={6}>
            <Typography variant="h3">Select a model to try</Typography>
            <Typography variant="h6">Note: These models are running on external server and the speed can be very slow.</Typography>
            <Typography variant="h6">Fast Model: <a href="https://google-sdxl.hf.space/?__theme=dark" target="_blank" rel="noreferrer"> Google Fast SDXL (External Link)</a></Typography>
            {/*<Button variant="contained" sx={{ color: "white", display: "inline-flex" }} onClick={() => openInNewTab("https://google-sdxl.hf.space/?__theme=dark")}>Fast SDXL</Button> */}
            
          </Grid> 
          {/*<div>
            <Typography variant="h4">Promt Generator:</Typography>
            <iframe loading="lazy" src="https://gustavosta-magicprompt-stable-diffusion.hf.space/" width="100%" height="1000" scrolling="yes" class="iframe-class ezlazyloaded" frameborder="0" ezoid="0.7569947658503948"></iframe>
          </div>*/}
            {/* not working */}
            {/*<iframe  target="_top" src="https://google-sdxl.hf.space/?__theme=dark" aria-label="Space app" className="container space-iframe flex-grow overflow-hidden bg-white p-0 outline-none dark:bg-white"  allow="accelerometer; ambient-light-sensor; autoplay; battery; camera; clipboard-write; document-domain; encrypted-media; fullscreen; geolocation; gyroscope; layout-animations; legacy-image-formats; magnetometer; microphone; midi; oversized-images; payment; picture-in-picture; publickey-credentials-get; sync-xhr; usb; vr ; wake-lock; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation" scrolling="no" id="iFrameResizer0" iframe-resizer=""  height="1684px"></iframe>
            */}
            {/*<iframe is="x-frame-bypass" src="https://ap123-illusiondiffusion.hf.space/?__theme=dark" aria-label="Space app" className="container space-iframe flex-grow overflow-hidden bg-white p-0 outline-none dark:bg-white" allow="accelerometer; ambient-light-sensor; autoplay; battery; camera; clipboard-write; document-domain; encrypted-media; fullscreen; geolocation; gyroscope; layout-animations; legacy-image-formats; magnetometer; microphone; midi; oversized-images; payment; picture-in-picture; publickey-credentials-get; sync-xhr; usb; vr ; wake-lock; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation" scrolling="no" id="iFrameResizer2" iframe-resizer="" overflow="hidden" height="1067px"></iframe>*/}
            {/*<iframe src="https://jbilcke-hf-ai-clip-factory.hf.space/?postId=f63df23d-de2f-4dee-961c-a56f160dd159&amp;prompt=pikachu%2C+working+on+a+computer%2C+office%2C+serious%2C+typing%2C+keyboard&amp;model=TheLastBen%2FPikachu_SDXL" aria-label="Space app" className=" space-iframe flex-grow overflow-hidden bg-white p-0 outline-none dark:bg-white" allow="accelerometer; ambient-light-sensor; autoplay; battery; camera; clipboard-write; document-domain; encrypted-media; fullscreen; geolocation; gyroscope; layout-animations; legacy-image-formats; magnetometer; microphone; midi; oversized-images; payment; picture-in-picture; publickey-credentials-get; sync-xhr; usb; vr ; wake-lock; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation" scrolling="yes" id="iFrameResizer0" overflow="auto"></iframe>*/}

            
            <div className="flex justify-content-center" style={{ marginTop: "50px", padding: '0px', backgroundColor: "black" }}>
            
            {/*<iframe src="https://jiawei011-dreamgaussian.hf.space/?__theme=dark" aria-label="Space app" className="container space-iframe flex-grow overflow-hidden bg-white p-0 outline-none dark:bg-white" allow="accelerometer; ambient-light-sensor; autoplay; battery; camera; clipboard-write; document-domain; encrypted-media; fullscreen; geolocation; gyroscope; layout-animations; legacy-image-formats; magnetometer; microphone; midi; oversized-images; payment; picture-in-picture; publickey-credentials-get; sync-xhr; usb; vr ; wake-lock; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation" scrolling="no" id="iFrameResizer1" iframe-resizer="" overflow="hidden" height="946px"></iframe>*/}
            <Grid container>
            <Grid item sm={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor:'#33eaff',
                    },
                  }}
                sx={{marginBottom: 10}}
                >
              <Tab label="Prompt Generator"/>
              <Tab label="Prompt Generator SD" />
              <Tab label="SDXL"/>              
              <Tab label="3D Modeling"/>              
              <Tab label="Dream Art" />
              <Tab label="Karlo Model" />
              <Tab label="Animation XL" />
                  <Tab label="Emi (Ethereal master of illustration)" />
              <Tab label="Controlnet (image-to-image)" />
              </Tabs>
            </Grid>
            </Grid>
            </div>
          <div >

            {              
              <Fragment>
                {console.log(value)}
                <iframe loading="lazy"  
                  style={{ display: value != 0 ? 'none' : 'block' }}
                    src="https://creataai-magicprompt-stable-diffusion.hf.space" width="100%" height="1000" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.7569947658503948"></iframe>
                  <iframe loading="lazy"
                    style={{ display: value != 1 ? 'none' : 'block' }}
                    src="https://doevent-stable-diffusion-prompt-generator.hf.space" width="100%" height="1000" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.7569947658503948"></iframe>
                  
                  < iframe
                  style={{ display: value != 2 ? 'none' : 'block' }}
                  src="https://prodia-sdxl-stable-diffusion-xl.hf.space/?__theme=dark" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.04917399584202686"></iframe>
                < iframe
                  style={{ display: value != 3 ? 'none' : 'block' }}
                src="https://jiawei011-dreamgaussian.hf.space/?__theme=dark" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.04917399584202686"></iframe>
                <iframe loading="lazy"
                  style={{ display: value != 4 ? 'none' : 'block' }}
                  src="https://phenomenon1981-dreamlikeart-diffusion-1-0.hf.space/" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameBorder="0" ezoid="0.884972891195361"></iframe>
                <iframe loading="lazy"
                  style={{ display: value != 5 ? 'none' : 'block' }}
                  src="https://kakaobrain-karlo.hf.space" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.43552609011386556"></iframe>
                <iframe loading="lazy"
                  style={{ display: value != 6 ? 'none' : 'block' }}
                  src="https://linaqruf-animagine-xl.hf.space" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.21295006792643978"></iframe>
                <iframe loading="lazy"
                  style={{ display: value != 7 ? 'none' : 'block' }}
                  src="https://aipicasso-emi-latest-demo.hf.space" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.08057562842658672"></iframe>
                <iframe loading="lazy"
                  style={{ display: value != 8 ? 'none' : 'block' }}
                  src="https://hysts-controlnet-v1-1.hf.space" width="100%" height="1850" scrolling="yes" className="iframe-class ezlazyloaded" frameborder="0" ezoid="0.04917399584202686"></iframe>
                </Fragment>
              }
                          
          </div>
          </ContainerCard>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Create