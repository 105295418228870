import React, { useEffect, useCallback, useState, useRef } from "react";
import PropTypes, { func } from "prop-types";
import classNames from "classnames";
import {
  Grid, Box, Typography, ThemeProvider, createTheme, Breadcrumbs, Link, Chip,
  Container, InputBase, IconButton, Button
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { styled, alpha } from '@mui/material/styles';
import BlogCard from "./BlogCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { blogPosts, blogTypes } from "../../BlogContents/blogContents"
import smoothScrollTop from "../smoothScrollTop";
import Nav from "../../Components/Nav/Nav"
import SearchIcon from '@mui/icons-material/Search';
import SubdirectoryArrowLeft from '@mui/icons-material/SubdirectoryArrowLeft';

const styles = (theme) => ({
  palette:
  {
    mode: 'light',
    background: {
      default: "#0f0f0f"
    }
    },
    
  blogContentWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    maxWidth: 1280,
    width: "100%",
  },
  wrapper: {
    minHeight: "60vh",
  },
  noDecoration: {
    textDecoration: "none !important",
  },
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: -5,
  marginTop: 5,
  width: '80%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '60ch',
    },
  },
}));

/** not working */
const theme = createTheme({
  palette: {
    mode: 'light',
  },
  background: '#f5f5f5'
},
);


function Blogs(props) {
  const { classes, showContentType} = props;
  const isWidthUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const [showBlogType, setShowBlogType] = useState('all');  //all, article, code, tutorial
  const [searchText, setSearchText] = useState('');
  const [useSearch, setUseSearch] = useState(false);
  //const [searchCount, setSearchCount] = useState(0);
  const searchCount = useRef(0)


  function filterPosts() {
    if (useSearch) {
      var filteredPosts = []
      blogPosts.forEach((post) => {
        console.log(`${post.title}`)
        if (post.title.toLowerCase().includes(searchText.toLowerCase())) {
          console.log(`found: ${searchText}`)
          filteredPosts.push(post)
        }
      });
      //setSearchCount(filteredPosts.length);
      searchCount.current = filteredPosts.length
      return filteredPosts
    } else {
      let filteredPosts = blogPosts.sort((a, b) => { return a.date < b.date }).filter((x) => {
        if (showBlogType == 'all') {
          return true;
        } else if (showBlogType == x.type) {
          return true;
        }
        return false;
      }).map((x) => { return x })
      return filteredPosts
    }
  }

  function getVerticalBlogPosts(isWidthUpSm, isWidthUpMd, blogPosts) {
    const gridRows = [[], [], []];
    let rows;
    let xs;
    if (isWidthUpMd) {
      rows = 3;
      xs = 4;
    } else if (isWidthUpSm) {
      rows = 2;
      xs = 6;
    } else {
      rows = 1;
      xs = 12;
    }
      //console.log(filteredPosts)
    let filtered = filterPosts()
    //if (useSearch) setSearchCount(filtered.length);
    filtered.forEach((blogPost, index) => {
      gridRows[index % rows].push(
        <Grid key={blogPost.title} item xs={12}>
          <Box mb={3}>
            <BlogCard
              key={blogPost.title}
              img={blogPost.img}
              blog={blogPost}
            />
          </Box>
        </Grid>
      );
    });
    return gridRows.map((element, index) => (
      <Grid key={index} item xs={xs}>
        {element}
      </Grid>
    ));
  }

  function showBlogCategories() {
    var elements = [];
    blogTypes.forEach((type) => {
      var ele;
      if (type == showBlogType) {
       ele = <Chip label={type} variant="outlined" key={type}
        />
      } else {
       ele = <Chip label={type} variant="filled" key={type}
         onClick={() => {
           setUseSearch(false)
           setShowBlogType(type)
         }
         }
        />

      }

      elements.push(ele)
    });
    return elements;
  }

  const search = useCallback(() => {
    if (searchText === undefined || searchText === '') {
      console.log(`search(): ${searchText}`)
      setUseSearch(false)
      return;
    }
    setUseSearch(true)
    console.log(`searching: ${searchText}`)
  },[searchText]);

  const selectBlog = useCallback(() => {
    smoothScrollTop();
    document.title = "Creata AI - Blog";
    //setSelectedTab("Blog");
  }, /*[setSelectedTab]*/);

  useEffect(() => {    
    selectBlog();
    //setShowBlogType(showContentType ?? "all");
    //setFilteredPosts(filterPosts())  // creates infinite loop
  }, [selectBlog, showContentType, filterPosts]);

  return (
        <>
      <Nav />

      <Container maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Blog</Typography>
        </Breadcrumbs>
        <div style={{ marginTop: "10px" }}>
          {
            showBlogCategories()
          }
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={ (event)=>{                                 //adding the onChange event
                setSearchText(event.target.value)
                console.log(`onChange: ${searchText}`)
            }}
              onBlur={()=>search()}
            />
            <Button variant="contained" onClick={() => search()} sx={{ color: "white" }} endIcon={<SubdirectoryArrowLeft />}>
              Search
            </Button>
          </Search>
          {/*<IconButton className={classes.iconButton} aria-label="search" sx={{color: "beige"}} onClick={() => search()}><SearchIcon/></IconButton>*/}
                    
        </div>
        {
          useSearch ?
            <Grid item sx={{marginTop:2}}>
              <Typography variant="bodytext" >Found: {searchCount.current} entries</Typography>
            </Grid>
        : null
       }
        <div>
          <Grid container sx={{marginTop:"50px"}}>
            <Grid item alignItems="center">
              {useSearch ?
                <Typography variant="h2"
                  align="center"
                  color="text.primary">
                  Creata Blog - Search "{searchText}" Result
                </Typography>
                :
                <Typography variant="h2"
                  align="center"
                  color="text.primary">
                  Creata AI Blog
                </Typography>
                
              }
            </Grid>
          </Grid>

        </div>
    <Box
      display="flex"
          justifyContent="center"
          className={classNames(classes.wrapper, "lg-p-top")}

    >
      <div className={classes.blogContentWrapper}>
        <Grid container spacing={3} key="blogs-1234">
          {getVerticalBlogPosts(isWidthUpSm, isWidthUpMd, blogPosts)}
        </Grid>
      </div>
        </Box>
        </Container>
    </>

      );
    
}

Blogs.propTypes = {
  classes: PropTypes.object.isRequired,
  showContentType: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Blogs);
