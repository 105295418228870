// import styles of this component
import styles from "./MasonryBox.module.css"
import { PropTypes } from 'prop-types';

// MasonryBox component
const MasonryBox = ({ src, prompt, callback}) => {

  function imageClicked() {
    console.log('image clicked')
  }
  return (
    <div className={styles["my-masonry"]} onClick={callback}>
        <img src={src} style={{ width: "100%" }} alt="creataAi, stable diffusion" />
        <div className={`${styles["my-masnry-description"]} flex`}>
          <div className={`${styles["my-masnry-user-box"]} flex align-items-center`}>
            <div className={styles["my-masnry-user-prof"]}>
            {/*<img src={userProf} alt="" /> */}
            </div>
            <div className={`${styles["my-masnry-user-prof-desc"]} flex flex-column`}>
              <h1>{prompt}</h1>
            {/*<h3>{userJob}</h3>*/}
            </div>
          </div>
        </div>
    </div>
  )
}

// validate MasonryBox component
MasonryBox.propTypes = {
  src: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  callback: PropTypes.func
}

export default MasonryBox