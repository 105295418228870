import React from 'react';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import rehypeRaw from 'rehype-raw';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { Box, Typography, Link } from '@mui/material'
//import remarkRehype from 'remark-rehype';
//import rehypeRaw from 'rehype-raw';
import blog1 from "./how-to-generate-art-using-ai.md"
import blog2 from "./unleashing-creativity-with-generative-ai-techniques.md"
import blog3 from "./transform-ideas-into-3D-models-using-AI.md"
import blog4 from "./Formulating-the-Best-Questions-for-ChatGPT-A-Guide-to-Productive-Conversations.md"
import blog5 from "./generative-ai-usage-guide.md"
import blog6 from "./Tutorial-Style-Transfer-for-Generative-AI.md"
import blog7 from "./pytorch_style_transfer_sample_code.md"
import blog8 from "./stable-diffusion-generative-art-ai-technology.md"
import blog9 from "./Best-Practice-Prompt-Selection-Stable-Diffusion-AI-Art.md"
import blog10 from "./crafting-prompts-stable-diffusion-art.md"
import blog11 from "./understanding-openai-dalle-generative-ai-model.md"
import blog12 from "./midjourney-tutorial-applications-artstyles-advantanges-drawbacks.md"
import blog13 from "./creating-effective-prompts-gpt3-business-marketing.md"
import blog14 from "./generative-ai-game-assets-generation.md"
import blog15 from "./creating-gpt-chat-prompts-business-development.md"
import blog16 from "./image-to-image-ai-technology.md"
import blog17 from "./creating-art-generative-AI-python.md"
import videoBlog from "./youtube_posts_final.json"
import blog18 from "./top-gpus-machine-learning.md"
import blog19 from "./Comparing-AWS-Azure-and-Google-Cloud-for-Machine-Learning-Model-Training.md"
import blog20 from "./understanding-stable-diffusion-sdxl.md"
import blog21 from "./comprehensive-guide-synth-music.md"
import blog22 from "./understanding-voice-cloning-AI.md"
import blog23 from "./tutorial-using-gpt-4-api-task-management.md"
import blog24 from "./m4a-to-wav-conversion-tutorial.md"
import blog25 from "./tutorial-musicgen-model-synth-music-creation.md"
import blog26 from "./tutorial-sound-file-editing-conversion.md"
import blog27 from "./understanding-voice-cloning.md"
import blog28 from "./understanding-generative-ai-models.md"
import blog29 from "./gpt-ai-enhanced-calendar-apps.md"
import blog30 from "./chatgpt-parameters-guide.md"
import blog31 from "./guide-prompt-generation-school-teachers.md"
import blog32 from "./tutorial-training-stable-diffusion-generative-AI-model.md"
import blog33 from "./developing-code-training-stable-diffusion-models.md"
import blog34 from "./gpt-resume-writing-help.md"
import blog35 from "./mastering-gpt-blog-drafting-tutorial.md"
import blog36 from "./leveraging-generative-ai-everyday-life-work.md"
import blog37 from "./GPT-Dall-E-Stable-Diffusion-Visual-Storytelling.md"
import blog38 from "./leveraging-gpt4-extract-web-content.md"
import blog39 from "./gpt-4-website-generation.md"
import blog40 from "./understanding-autogpt-creating-guide.md"
import blog41 from "./chatgpt-article-rewriting-guide.md"
import blog42 from "./tutorial-using-gpt4-article-rewriting.md"
import blog43 from "./gpt-llama-architecture-software-components.md"
import blog44 from "./Step-by-step-Guide-for-Installing-GPT4All.md"
import blog45 from "./ai-music-composer-terms-and-guide.md"
import blog46 from "./How-to-Install-and-Use-PrivateGPT.md"
import blog47 from "./Embedding-in-LLAMA-explained.md"
import blog48 from "./Comparing-Local-GPT-Solutions_AutoGPT-anyhtingLLM-GPT4All-PrivateGPT.md"
import blog49 from "./mastering-gpt-prompts-business-marketing.md"
import blog50 from "./processing-cvs-files-spreadsheets-gpt4.md"
import blog51 from "./create-chatgpt-plugin-guide.md"
import blog52 from "./vector-database-chatbot-intelligence.md"
import blog53 from "./top-5-vector-database-chatbot-application.md"
import blog54 from "./ai-calendar-gpt4.md"
import blog55 from "./autogen-ai-agent-framework.md"


//console.log(`${videoBlog[0].link}`)

const markdown = `Find out more from: https://creataai.com.`

const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h4',
                padding: 5
            },
        },
        h2: {
            component: Typography,
            props: {
                gutterBottom: true, variant: 'h6', padding: 10, marginBottom: 20
},
        },
        h3: {
            component: Typography,
            props: { gutterBottom: true, variant: 'subtitle1' },
        },
        h4: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'caption',
                paragraph: true,
                padding: 5

            },
        },
        p: {
            component: Typography,
            props: {
                paragraph: true, padding: 20, lineHeight: 2
},
        },
        a: { component: Link },
        li: {
            component: (props) => (
                <Box component="li" sx={{ mt: 1, mb:1, ml: 10 }}>
                    <Typography component="span" {...props} />
                </Box>
            ),
        },
    }
}

const styles = {
    ...getOverrides(), 
    whiteSpace: 'pre-line',
    h1: {
        component: 'p',
        props: {
            style: { fontSize: 30, marginTop: 30 },
        },
    },
    h2: {
        component: 'p',
        props: {
            style: { fontSize: 28, fontWeight: 600, marginTop: 30 },
        },
    },
    h3: {
        component: 'p',
        props: {
            style: { fontSize: 26, fontWeight: 600, marginTop: 30 },
        },
    },
    p: {
        component: 'p',
        props: {
            style: { lineHeight: 2, fontSize: 20, whiteSpace: 'pre-line', marginBottom: 30 },
        },
    },
        li: {
            component: 'li',
            props: {
                style: { lineHeight: 2, fontSize: 18 },
            },
    },

}
//const styleOveride = (overrides = {{...getOverrides(), options}} )

const blogTypes = ['all', 'article', 'tutorial', 'code', 'video']

        
const blogPosts = [
    {
        title: 'How to Generate Art Using AI',
        link: '/blog/how-to-generate-art-using-ai',
        icon: OutlinedFlagRoundedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog1}</MuiMarkdown>),
        url: "",
        date: 1689093255,
        snippet: 'Art has always been a unique form of human expression, a medium through which individuals can convey emotions, ideas, and stories.',
        img: 'https://source.unsplash.com/ggeXPf_ykAU',
        type: "article"
    },
    {
        title: 'Unleashing Creativity with Generative AI Techniques',
        link: '/blog/unleashing-creativity-with-generative-ai-techniques',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog2}</MuiMarkdown>), 
        url: "",
        date: 1689923255,
        snippet: 'In the realm of artificial intelligence, Generative AI techniques are the creative powerhouses',
        img: 'https://source.unsplash.com/VEAhusrAuXU',
        type: "article"
    },
    {
        title: 'Transform Ideas into 3D Models Using AI',
        link: '/blog/transform-ideas-into-3D-models-using-AI',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog3}</MuiMarkdown>),
        url: "",
        date: 1689923255,
        snippet: 'In the ever-evolving landscape of technology, 2D to 3D generators powered by artificial intelligence (AI) have emerged as game-changers',
        img: 'https://source.unsplash.com/52afknBiUk4',
        type: "article"
    },
    {
        title: 'Formulating the Best Questions for ChatGPT: A Guide to Productive Conversations',
        link: '/blog/Formulating-the-Best-Questions-for-ChatGPT-A-Guide-to-Productive-Conversations',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog4}</MuiMarkdown>),
        url: "",
        date: 1690160082,
        snippet: 'How to ask chatGPT the right questions is becoming an essential skill',
        img: 'https://imagedelivery.net/x0RJTBjgra1v_IP6ikii7A/e41a89e5-9ac1-4fca-41c5-dd6d64305700/768w',
        type: "article"
    },
    {
        title: 'Comprehensive Guide on Using Generative AI Effectively',
        link: '/blog/generative-ai-usage-guide',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog5}</MuiMarkdown>),
        url: "https://creataai.com//blog/generative-ai-usage-guide",
        date: 1690250356,
        snippet: 'Unravel the potential of Generative AI with our detailed guide. Understand its working, applications, steps to use it effectively and overcome challenges',
        img: 'https://imagedelivery.net/x0RJTBjgra1v_IP6ikii7A/830cdf1f-d87b-44b8-98e8-683cd64abe00/768w',
        type: "article"
    },
    {
        title: 'Style Transfer for Generative AI: A Step-by-Step Tutorial',
        link: '/blog/Tutorial-Style-Transfer-for-Generative-AI',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog6}</MuiMarkdown>),
        url: "https://creataai.com/blog/Tutorial-Style-Transfer-for-Generative-AI",
        date: 1690335790,
        snippet: 'Style transfer is an exciting application of deep learning techniques',
        img: 'https://source.unsplash.com/3Sf_G9m0gcQ',
        type: "tutorial"
    },
    {
        title: 'PyTorch Style Transfer Sample Code',
        link: '/blog/PyTorch-Style-Transfer-Sample-Code-for-Generative-AI',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog7}</MuiMarkdown>),
        url: "https://creataai.com/blog/PyTorch-Style-Transfer-Sample-Code-for-Generative-AI",
        date: 1690368768,
        snippet: 'Style transfer implementation in PyTorch',
        img: 'https://source.unsplash.com/Skf7HxARcoc',
        type: "code"
    },
    {
        title: 'Exploring Stable Diffusion in Generative Art AI Tech',
        link: '/blog/stable-diffusion-generative-art-ai-technology',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog8}</MuiMarkdown>),
        url: "https://creataai.com/blog/stable-diffusion-generative-art-ai-technology",
        date: 1690533849,
        snippet: 'Dive deep into Stable Diffusion, a revolutionary AI technology transforming the landscape of Generative Art. Discover its role, workings and future potential.',
        img: 'https://imagedelivery.net/x0RJTBjgra1v_IP6ikii7A/1b2140ef-c9fa-40e6-6d73-d262652cb300/768w',
        type: "article"
    },
    {
        title: 'Master the Art of Prompt Selection for Stable Diffusion AI Art Generation',
        link: '/blog/Best-Practice-Prompt-Selection-Stable-Diffusion-AI-Art',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog9}</MuiMarkdown>),
        url: "https://creataai.com/blog/Best-Practice-Prompt-Selection-Stable-Diffusion-AI-Art",
        date: 1690794630,
        snippet: 'Dive deep into Stable Diffusion, a revolutionary AI technology transforming the landscape of Generative Art. Discover its role, workings and future potential.',
        img: 'https://source.unsplash.com/QeaGAUjW_fw',
        type: "article"
    },
    {
        title: 'Creating Effective Prompts for Beautiful Stable Diffusion Art',
        link: '/blog/crafting-prompts-stable-diffusion-art',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog10}</MuiMarkdown>),
        url: "https://creataai.com/blog/crafting-prompts-stable-diffusion-art",
        date: 1690887921,
        snippet: "Learn how to craft powerful prompts to generate breathtaking stable diffusion art. From understanding the art form, creating impactful prompts, to using them effectively, we\'ve got you covered.",
        img: 'https://source.unsplash.com/cXJ8dCR4PvE',
        type: "article"
    },
    {
        title: "Comprehensive Guide to OpenAI's DALLE: The Future of Generative AI Models",
        link: '/blog/understanding-openai-dalle-generative-ai-model',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog11}</MuiMarkdown>),
        url: "https://creataai.com/blog/understanding-openai-dalle-generative-ai-model",
        date: 1691149117,
        snippet: "Gain an extensive understanding of OpenAI's revolutionary DALLE generative AI model, its components, and potential applications along with a glance at its future.",
        img: 'https://source.unsplash.com/bNEga8u4kUs',
        type: "article"
    },
    {
        title: "Comprehensive Guide to Mastering Midjourney: Applications, Art Styles, Pros and Cons",
        link: '/blog/midjourney-tutorial-applications-artstyles-advantanges-drawbacks',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog12}</MuiMarkdown>),
        url: "https://creataai.com/blog/midjourney-tutorial-applications-artstyles-advantanges-drawbacks",
        date: 1694259012,
        snippet: "Gain an extensive understanding of OpenAI's revolutionary DALLE generative AI model, its components, and potential applications along with a glance at its future.",
        img: 'https://source.unsplash.com/EHLd2utEf68',
        type: "article"
    },
    {
        title: "Tutorial on Creating Effective GPT-3 Prompts for Business Marketing",
        link: '/blog/creating-effective-prompts-gpt3-business-marketing',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog13}</MuiMarkdown>),
        url: "https://creataai.com/blog/creating-effective-prompts-gpt3-business-marketing",
        date: 1694429724,
        snippet: "A comprehensive guide on how to craft and optimize effective prompts for the GPT-3 AI model in a business marketing context, including real-world examples and success measurements.",
        img: 'https://source.unsplash.com/agFmImWyPso',
        type: "tutorial"
    },
    {
        title: "Exploring Generative AI in Game Asset Creation",
        link: '/blog/generative-ai-game-assets-generation',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog14}</MuiMarkdown>),
        url: "https://creataai.com/blog/generative-ai-game-assets-generation",
        date: 1694591904,
        snippet: "Uncover the transformative power of generative AI in game development. Learn how it reshapes asset creation with real-world examples, benefits, and challenges.",
        img: 'https://source.unsplash.com/FdTmaUlEr4A',
        type: "article"
    },
    {
        title: "Creating GPT Prompts for Business Development - A Detailed Tutorial",
        link: '/blog/creating-gpt-chat-prompts-business-development',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog15}</MuiMarkdown>),
        url: "https://creataai.com/blog/creating-gpt-chat-prompts-business-development",
        date: 1694685731,
        snippet: "A step-by-step guide to developing effective GPT chat prompts for business development. Learn about the key elements and strategies to engage your customers through AI.",
        img: 'https://source.unsplash.com/hpjSkU2UYSU',
        type: "tutorial"
    },
    {
        title: "Deep Dive into Image to Image AI Technology",
        link: '/blog/image-to-image-ai-technology',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog16}</MuiMarkdown>),
        url: "https://creataai.com/blog/image-to-image-ai-technology",
        date: 1692168067,
        snippet: "Explore the basics of Image to Image AI technology, its diverse applications, and future prospects. Gain insights into how this revolutionary tech is shaping various industries.",
        img: 'https://source.unsplash.com/KamUyOCH3iE',
        type: "article"
    },
    {
        title: "Guide to Creating Art with Generative AI using Python",
        link: '/blog/creating-art-generative-AI-python',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog17}</MuiMarkdown>),
        url: "https://creataai.com/blog/creating-art-generative-AI-python",
        date: 1692240753,
        snippet: "A comprehensive guide for using Python and generative AI to create unique art pieces. From setting up your environment to writing and testing your code, we've got you covered.",
        img: 'https://source.unsplash.com/cvBBO4PzWPg',
        type: "code"
    },
    {
        title: videoBlog[0].title,
        link: `/blog/video/${videoBlog[0].title}` ,
        icon: InfoOutlinedIcon,
        content: videoBlog[0].title,
        url: videoBlog[0].videoLink,
        date: videoBlog[0].timestamp,
        snippet: videoBlog[0].snippet,
        img: videoBlog[0].img,
        type: "video"
    },
    {
        title: videoBlog[1].title,
        link: `/blog/video/${videoBlog[1].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[1].title,
        url: videoBlog[1].videoLink,
        date: videoBlog[1].timestamp,
        snippet: videoBlog[1].snippet,
        img: videoBlog[1].img,
        type: "video"
    },
    {
        title: videoBlog[2].title,
        link: `/blog/video/${videoBlog[2].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[2].title,
        url: videoBlog[2].videoLink,
        date: videoBlog[2].timestamp,
        snippet: videoBlog[2].snippet,
        img: videoBlog[2].img,
        type: "video"
    },
    {
        title: videoBlog[3].title,
        link: `/blog/video/${videoBlog[3].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[3].title,
        url: videoBlog[3].videoLink,
        date: videoBlog[3].timestamp,
        snippet: videoBlog[3].snippet,
        img: videoBlog[3].img,
        type: "video"
    },
    {
        title: "15 Best GPUs for Machine Learning in 2023",
        link: '/blog/top-gpus-machine-learning',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog18}</MuiMarkdown>),
        url: "https://creataai.com/blog/top-gpus-machine-learning",
        date: 1695075907,
        snippet: "Explore the basics of Image to Image AI technology, its diverse applications, and future prospects. Gain insights into how this revolutionary tech is shaping various industries.",
        img: 'https://source.unsplash.com/Nk2vzMpIi0k',
        type: "article"
    },
    {
        title: videoBlog[4].title,
        link: `/blog/video/${videoBlog[4].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[4].title,
        url: videoBlog[4].videoLink,
        date: videoBlog[4].timestamp,
        snippet: videoBlog[4].snippet,
        img: videoBlog[4].img,
        type: "video"
    },
    {
        title: "Comparing the Top Cloud Services for Machine Learning Model Training: AWS, Azure, and Google Cloud",
        link: '/blog/Comparing-AWS-Azure-and-Google-Cloud-for-Machine-Learning-Model-Training',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog19}</MuiMarkdown>),
        url: "https://creataai.com/blog/Comparing-AWS-Azure-and-Google-Cloud-for-Machine-Learning-Model-Training",
        date: 1692441009,
        snippet: "Amazon Web Services (AWS), Microsoft Azure, and Google Cloud are three of the leading cloud platforms for ML model training. This article will compare these three platforms in terms of cost, features, and ease of use.",
        img: 'https://source.unsplash.com/LqKhnDzSF-8',
        type: "article"
    },
    {
        title: "A Comprehensive Guide on Stable Diffusion and SDXL",
        link: '/blog/understanding-stable-diffusion-sdxl',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog20}</MuiMarkdown>),
        url: "https://creataai.com/blog/understanding-stable-diffusion-sdxl",
        date: 1692595235,
        snippet: "This article provides a detailed explanation of Stable Diffusion and SDXL. It explores their characteristics, differences, and real-world applications.",
        img: 'https://imagedelivery.net/x0RJTBjgra1v_IP6ikii7A/1ac0f4c2-22f6-43c3-3c88-56e63aaf6000/768w',
        type: "article"
    },
    {
        title: videoBlog[5].title,
        link: `/blog/video/${videoBlog[5].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[5].title,
        url: videoBlog[5].videoLink,
        date: videoBlog[5].timestamp,
        snippet: videoBlog[5].snippet,
        img: videoBlog[5].img,
        type: "video"
    },
    {
        title: "A Comprehensive Tutorial on Synth Music | Understand & Create",
        link: '/blog/comprehensive-guide-synth-music',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog21}</MuiMarkdown>),
        url: "https://creataai.com/blog/comprehensive-guide-synth-music",
        date: 1692697156,
        snippet: "From key terms to creating your own compositions, this detailed guide offers a comprehensive look into the world of synth music.",
        img: 'https://source.unsplash.com/QfN0l0MZCyc',
        type: "tutorial"
    },
    {
        title: videoBlog[6].title,
        link: `/blog/video/${videoBlog[6].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[6].title,
        url: videoBlog[6].videoLink,
        date: videoBlog[6].timestamp,
        snippet: videoBlog[6].snippet,
        img: videoBlog[6].img,
        type: "video"
    },
    {
        title: videoBlog[7].title,
        link: `/blog/video/${videoBlog[7].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[7].title,
        url: videoBlog[7].videoLink,
        date: videoBlog[7].timestamp,
        snippet: videoBlog[7].snippet,
        img: videoBlog[7].img,
        type: "video"
    },
    {
        title: "Unveiling Voice Cloning: The Process and AI Involvement",
        link: '/blog/understanding-voice-cloning-AI',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog22}</MuiMarkdown>),
        url: "https://creataai.com/blog/understanding-voice-cloning-AI",
        date: 1695459181,
        snippet: "Delve into the intriguing world of voice cloning technology. Understand the process, the role of Artificial Intelligence, and its diverse applications and implications.",
        img: 'https://source.unsplash.com/HowWHYGqFF4',
        type: "article"
    },
    {
        title: videoBlog[8].title,
        link: `/blog/video/${videoBlog[8].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[8].title,
        url: videoBlog[8].videoLink,
        date: videoBlog[8].timestamp,
        snippet: videoBlog[8].snippet,
        img: videoBlog[8].img,
        type: "video"
    },
    {
        title: videoBlog[9].title,
        link: `/blog/video/${videoBlog[9].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[9].title,
        url: videoBlog[9].videoLink,
        date: videoBlog[9].timestamp,
        snippet: videoBlog[9].snippet,
        img: videoBlog[9].img,
        type: "video"
    },
    {
        title: "Guide to Using GPT-4 API for Task Management: Creating Weekly Schedules",
        link: '/blog/tutorial-using-gpt-4-api-task-management',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog23}</MuiMarkdown>),
        url: "https://creataai.com/blog/tutorial-using-gpt-4-api-task-management",
        date: 1695764908,
        snippet: "Learn how to leverage the power of GPT-4 API for task management. Our comprehensive guide walks you through creating a weekly schedule using AI.",
        img: 'https://source.unsplash.com/Fc1GBkmV-Dw',
        type: "tutorial"
    },
    {
        title: videoBlog[10].title,
        link: `/blog/video/${videoBlog[10].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[10].title,
        url: videoBlog[10].videoLink,
        date: videoBlog[10].timestamp,
        snippet: videoBlog[10].snippet,
        img: videoBlog[10].img,
        type: "video"
    },
    {
        title: videoBlog[11].title,
        link: `/blog/video/${videoBlog[11].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[11].title,
        url: videoBlog[11].videoLink,
        date: videoBlog[11].timestamp,
        snippet: videoBlog[11].snippet,
        img: videoBlog[11].img,
        type: "video"
    },
    {
        title: videoBlog[12].title,
        link: `/blog/video/${videoBlog[12].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[12].title,
        url: videoBlog[12].videoLink,
        date: videoBlog[12].timestamp,
        snippet: videoBlog[12].snippet,
        img: videoBlog[12].img,
        type: "video"
    },
    {
        title: videoBlog[13].title,
        link: `/blog/video/${videoBlog[13].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[13].title,
        url: videoBlog[13].videoLink,
        date: videoBlog[13].timestamp,
        snippet: videoBlog[13].snippet,
        img: videoBlog[13].img,
        type: "video"
    },
    {
        title: "Step-by-Step Guide to Convert M4A to WAV for AI Audio Projects",
        link: '/blog/m4a-to-wav-conversion-tutorial',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog24}</MuiMarkdown>),
        url: "https://creataai.com/blog/m4a-to-wav-conversion-tutorial",
        date: 1696068961,
        snippet: "Explore an easy-to-follow tutorial on converting M4A audio files to the WAV format specifically for use in AI audio projects. Learn about the need, process, and troubleshooting common issues during conversion.",
        img: 'https://source.unsplash.com/X-etICbUKec',
        type: "tutorial"
    },
    {
        title: videoBlog[14].title,
        link: `/blog/video/${videoBlog[14].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[14].title,
        url: videoBlog[14].videoLink,
        date: videoBlog[14].timestamp,
        snippet: videoBlog[14].snippet,
        img: videoBlog[14].img,
        type: "video"
    },
    {
        title: videoBlog[15].title,
        link: `/blog/video/${videoBlog[15].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[15].title,
        url: videoBlog[15].videoLink,
        date: videoBlog[15].timestamp,
        snippet: videoBlog[15].snippet,
        img: videoBlog[15].img,
        type: "video"
    },
    {
        title: "Code Tutorial: Generating Synth Music with Opensource Musicgen Model",
        link: '/blog/tutorial-musicgen-model-synth-music-creation',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog25}</MuiMarkdown>),
        url: "https://creataai.com/blog/tutorial-musicgen-model-synth-music-creation",
        date: 1696144493,
        snippet: "Learn to create synth music using the opensource Musicgen model. From setting up your environment to training your model, this step-by-step tutorial guides you through every detail.",
        img: 'https://source.unsplash.com/VZNYO4suM5o',
        type: "code"
    },
    {
        title: videoBlog[17].title,
        link: `/blog/video/${videoBlog[17].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[17].title,
        url: videoBlog[17].videoLink,
        date: videoBlog[17].timestamp,
        snippet: videoBlog[17].snippet,
        img: videoBlog[17].img,
        type: "video"
    },
    {
        title: videoBlog[16].title,
        link: `/blog/video/${videoBlog[16].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[16].title,
        url: videoBlog[16].videoLink,
        date: videoBlog[16].timestamp,
        snippet: videoBlog[16].snippet,
        img: videoBlog[16].img,
        type: "video"
    },
    {
        title: "Sound File Editing & Conversion – A Comprehensive Tutorial",
        link: '/blog/tutorial-sound-file-editing-conversion',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog26}</MuiMarkdown>),
        url: "https://creataai.com/blog/tutorial-sound-file-editing-conversion",
        date: 1693695571,
        snippet: "Learn about sound file editing and conversion in this detailed tutorial. Understand the difference between formats like WAV, MP3 and M4A, and discover the best tools for editing and converting.",
        img: 'https://source.unsplash.com/eybM9n4yrpE',
        type: "tutorial"
    },
    {
        title: videoBlog[18].title,
        link: `/blog/video/${videoBlog[18].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[18].title,
        url: videoBlog[18].videoLink,
        date: videoBlog[18].timestamp,
        snippet: videoBlog[18].snippet,
        img: videoBlog[18].img,
        type: "video"
    },
    {
        title: videoBlog[19].title,
        link: `/blog/video/${videoBlog[19].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[19].title,
        url: videoBlog[19].videoLink,
        date: videoBlog[19].timestamp,
        snippet: videoBlog[19].snippet,
        img: videoBlog[19].img,
        type: "video"
    },
    {
        title: "A Comprehensive Guide to Understanding Voice Cloning",
        link: '/blog/understanding-voice-cloning',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog27}</MuiMarkdown>),
        url: "https://creataai.com/blog/understanding-voice-cloning",
        date: 1696481445,
        snippet: "Dive deep into the world of voice cloning. Discover what it is, how it works, its practical applications, and the ethical considerations surrounding this revolutionary technology.",
        img: 'https://source.unsplash.com/PdO-fDWXQ5I',
        type: "article"
    },
    {
        title: videoBlog[20].title,
        link: `/blog/video/${videoBlog[20].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[20].title,
        url: videoBlog[20].videoLink,
        date: videoBlog[20].timestamp,
        snippet: videoBlog[20].snippet,
        img: videoBlog[20].img,
        type: "video"
    },
    {
        title: videoBlog[21].title,
        link: `/blog/video/${videoBlog[21].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[21].title,
        url: videoBlog[21].videoLink,
        date: videoBlog[21].timestamp,
        snippet: videoBlog[21].snippet,
        img: videoBlog[21].img,
        type: "video"
    },
    {
        title: "Comprehensive Guide to Understanding Generative AI Models",
        link: '/blog/understanding-generative-ai-models',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog28}</MuiMarkdown>),
        url: "https://creataai.com/blog/understanding-generative-ai-models",
        date: 1696560193,
        snippet: "Explore the world of artificial intelligence through a detailed explanation and list of various generative AI models such as GANs, VAEs, and RBMs.",
        img: 'https://source.unsplash.com/ZPOoDQc8yMw',
        type: "article"
    },
    {
        title: videoBlog[22].title,
        link: `/blog/video/${videoBlog[22].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[22].title,
        url: videoBlog[22].videoLink,
        date: videoBlog[22].timestamp,
        snippet: videoBlog[22].snippet,
        img: videoBlog[22].img,
        type: "video"
    },
    {
        title: "Utilizing GPT and AI to Enhance Calendar Apps",
        link: '/blog/gpt-ai-enhanced-calendar-apps',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog29}</MuiMarkdown>),
        url: "https://creataai.com/blog/gpt-ai-enhanced-calendar-apps",
        date: 1696654112,
        snippet: "Explore how the potent combination of GPT and AI can revolutionize the capabilities and functionality of your favorite calendar apps.",
        img: 'https://source.unsplash.com/Wkut4zCLCgs',
        type: "article"
    },
    {
        title: videoBlog[23].title,
        link: `/blog/video/${videoBlog[23].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[23].title,
        url: videoBlog[23].videoLink,
        date: videoBlog[23].timestamp,
        snippet: videoBlog[23].snippet,
        img: videoBlog[23].img,
        type: "video"
    },
    {
        title: videoBlog[24].title,
        link: `/blog/video/${videoBlog[24].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[24].title,
        url: videoBlog[24].videoLink,
        date: videoBlog[24].timestamp,
        snippet: videoBlog[24].snippet,
        img: videoBlog[24].img,
        type: "video"
    },
    {
        title: "Mastering ChatGPT Parameters: Your Ultimate How-To Guide",
        link: '/blog/chatgpt-parameters-guide',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog30}</MuiMarkdown>),
        url: "https://creataai.com/blog/chatgpt-parameters-guide",
        date: 1695253138,
        snippet: "Explore the world of AI Conversations with our definitive guide on using ChatGPT parameters. Understand, master and apply these parameters to enhance your AI text generation.",
        img: 'https://source.unsplash.com/LyI1T85Lry8',
        type: "article"
    },
    {
        title: videoBlog[25].title,
        link: `/blog/video/${videoBlog[25].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[25].title,
        url: videoBlog[25].videoLink,
        date: videoBlog[25].timestamp,
        snippet: videoBlog[25].snippet,
        img: videoBlog[25].img,
        type: "video"
    },
    {
        title: videoBlog[26].title,
        link: `/blog/video/${videoBlog[26].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[26].title,
        url: videoBlog[26].videoLink,
        date: videoBlog[26].timestamp,
        snippet: videoBlog[26].snippet,
        img: videoBlog[26].img,
        type: "video"
    },
    {
        title: "Mastering the Art of Prompt Generation: A Comprehensive Guide for School Teachers",
        link: '/blog/guide-prompt-generation-school-teachers',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog31}</MuiMarkdown>),
        url: "https://creataai.com/blog/guide-prompt-generation-school-teachers",
        date: 1696813110,
        snippet: "Explore the comprehensive guide on generating engaging prompts that enhance learning, with strategies and examples for effective teaching.",
        img: 'https://source.unsplash.com/zFSo6bnZJTw',
        type: "tutorial"
    },
    {
        title: videoBlog[27].title,
        link: `/blog/video/${videoBlog[27].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[27].title,
        url: videoBlog[27].videoLink,
        date: videoBlog[27].timestamp,
        snippet: videoBlog[27].snippet,
        img: videoBlog[27].img,
        type: "video"
    },
    {
        title: videoBlog[28].title,
        link: `/blog/video/${videoBlog[28].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[28].title,
        url: videoBlog[28].videoLink,
        date: videoBlog[28].timestamp,
        snippet: videoBlog[28].snippet,
        img: videoBlog[28].img,
        type: "video"
    },
    {
        title: "Comprehensive Guide on Training Stable Diffusion Generative AI with Custom Images & Styles",
        link: '/blog/tutorial-training-stable-diffusion-generative-AI-model',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog32}</MuiMarkdown>),
        url: "https://creataai.com/blog/tutorial-training-stable-diffusion-generative-AI-model",
        date: 1694321589,
        snippet: "Uncover the steps to train a stable diffusion generative AI model using custom images and styles. Learn about algorithm implementation, image processing, style transfer, and stability testing.",
        img: 'https://source.unsplash.com/FDtJZDU8kws',
        type: "tutorial",
        related: "blog33"
    },
    {
        title: videoBlog[29].title,
        link: `/blog/video/${videoBlog[29].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[29].title,
        url: videoBlog[29].videoLink,
        date: videoBlog[29].timestamp,
        snippet: videoBlog[29].snippet,
        img: videoBlog[29].img,
        type: "video"
    },
    {
        title: videoBlog[30].title,
        link: `/blog/video/${videoBlog[30].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[30].title,
        url: videoBlog[30].videoLink,
        date: videoBlog[30].timestamp,
        snippet: videoBlog[30].snippet,
        img: videoBlog[30].img,
        type: "video"
    },
    {
        title: "Developing Code for Stable Diffusion Models with Python - A Comprehensive Guide",
        link: '/blog/developing-code-training-stable-diffusion-models',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog33}</MuiMarkdown>),
        url: "https://creataai.com/blog/developing-code-training-stable-diffusion-models",
        date: 1696915251,
        snippet: " A comprehensive guide that provides information on how to develop code for training stable diffusion models using custom images and Python.",
        img: 'https://source.unsplash.com/m_HRfLhgABo',
        type: "code",
        ralated: "blog32"
    },
    {
        title: videoBlog[31].title,
        link: `/blog/video/${videoBlog[31].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[31].title,
        url: videoBlog[31].videoLink,
        date: videoBlog[31].timestamp,
        snippet: videoBlog[31].snippet,
        img: videoBlog[31].img,
        type: "video"
    },
    {
        title: videoBlog[32].title,
        link: `/blog/video/${videoBlog[32].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[32].title,
        url: videoBlog[32].videoLink,
        date: videoBlog[32].timestamp,
        snippet: videoBlog[32].snippet,
        img: videoBlog[32].img,
        type: "video"
    },
    {
        title: "Harnessing GPT for Enhancing Resume Writing Skills",
        link: '/blog/gpt-resume-writing-help',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog34}</MuiMarkdown>),
        url: "https://creataai.com/blog/gpt-resume-writing-help",
        date: 1697020596,
        snippet: "Explore how to use Generative Pre-training Transformer (GPT), an AI writing tool, to draft, refine and improve your resume.",
        img: 'https://source.unsplash.com/OQMZwNd3ThU',
        type: "tutorial",
    },
    {
        title: videoBlog[33].title,
        link: `/blog/video/${videoBlog[33].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[33].title,
        url: videoBlog[33].videoLink,
        date: videoBlog[33].timestamp,
        snippet: videoBlog[33].snippet,
        img: videoBlog[33].img,
        type: "video"
    },
    {
        title: videoBlog[34].title,
        link: `/blog/video/${videoBlog[34].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[34].title,
        url: videoBlog[34].videoLink,
        date: videoBlog[34].timestamp,
        snippet: videoBlog[34].snippet,
        img: videoBlog[34].img,
        type: "video"
    },
    {
        title: "Ultimate Guide to Using GPT for Blog Drafting",
        link: '/blog/mastering-gpt-blog-drafting-tutorial',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog35}</MuiMarkdown>),
        url: "https://creataai.com/blog/mastering-gpt-blog-drafting-tutorial",
        date: 1697097953,
        snippet: "Learn how to harness the power of GPT for drafting blogs. Understand its working, benefits, usage, and optimization strategies for best results.",
        img: 'https://source.unsplash.com/Fc1GBkmV-Dw',
        type: "tutorial",
    },
    {
        title: videoBlog[35].title,
        link: `/blog/video/${videoBlog[35].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[35].title,
        url: videoBlog[35].videoLink,
        date: videoBlog[35].timestamp,
        snippet: videoBlog[35].snippet,
        img: videoBlog[35].img,
        type: "video"
    },
    {
        title: videoBlog[36].title,
        link: `/blog/video/${videoBlog[36].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[36].title,
        url: videoBlog[36].videoLink,
        date: videoBlog[36].timestamp,
        snippet: videoBlog[36].snippet,
        img: videoBlog[36].img,
        type: "video"
    },
    {
        title: "Leveraging Generative AI in Everyday Life and Work",
        link: '/blog/leveraging-generative-ai-everyday-life-work',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog36}</MuiMarkdown>),
        url: "https://creataai.com/blog/leveraging-generative-ai-everyday-life-work",
        date: 1697235173,
        snippet: "Explore how generative AI, including technologies like GPT, revolutionize everyday tasks and professional operations, while maintaining ethical standards.",
        img: 'https://source.unsplash.com/2EJCSULRwC8',
        type: "article",
    },

    {
        title: videoBlog[37].title,
        link: `/blog/video/${videoBlog[37].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[37].title,
        url: videoBlog[37].videoLink,
        date: videoBlog[37].timestamp,
        snippet: videoBlog[37].snippet,
        img: videoBlog[37].img,
        type: "video"
    },
    {
        title: videoBlog[38].title,
        link: `/blog/video/${videoBlog[38].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[38].title,
        url: videoBlog[38].videoLink,
        date: videoBlog[38].timestamp,
        snippet: videoBlog[38].snippet,
        img: videoBlog[38].img,
        type: "video"
    },
    {
        title: "Exploring the Fusion of GPT, Dall-E and Stable Diffusion for Visual Storytelling",
        link: '/blog/GPT-Dall-E-Stable-Diffusion-Visual-Storytelling',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog37}</MuiMarkdown>),
        url: "https://creataai.com/blog/GPT-Dall-E-Stable-Diffusion-Visual-Storytelling",
        date: 1697243362,
        snippet: "Discover how the combination of GPT, Dall-E, and Stable Diffusion is revolutionizing the creation of visual storybooks. Learn more about these innovative AI technologies.",
        img: 'https://source.unsplash.com/mmPK8J0XpAM',
        type: "article",
    },

    {
        title: videoBlog[39].title,
        link: `/blog/video/${videoBlog[39].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[39].title,
        url: videoBlog[39].videoLink,
        date: videoBlog[39].timestamp,
        snippet: videoBlog[39].snippet,
        img: videoBlog[39].img,
        type: "video"
    },
    {
        title: videoBlog[40].title,
        link: `/blog/video/${videoBlog[40].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[40].title,
        url: videoBlog[40].videoLink,
        date: videoBlog[40].timestamp,
        snippet: videoBlog[40].snippet,
        img: videoBlog[40].img,
        type: "video"
    },
    {
        title: "Guide to Utilize GPT-4 for Web Content Extraction",
        link: '/blog/leveraging-gpt4-extract-web-content',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog38}</MuiMarkdown>),
        url: "https://creataai.com/blog/leveraging-gpt4-extract-web-content",
        date: 1697243362,
        snippet: "Learn how to effectively use the advanced capabilities of GPT-4 in extracting and analyzing web page content. Understand its strengths, setup process, and fine-tuning tips.",
        img: 'https://source.unsplash.com/5fNmWej4tAA',
        type: "article",
    },

    {
        title: videoBlog[41].title,
        link: `/blog/video/${videoBlog[41].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[41].title,
        url: videoBlog[41].videoLink,
        date: videoBlog[41].timestamp,
        snippet: videoBlog[41].snippet,
        img: videoBlog[41].img,
        type: "video"
    },
    {
        title: videoBlog[42].title,
        link: `/blog/video/${videoBlog[42].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[42].title,
        url: videoBlog[42].videoLink,
        date: videoBlog[42].timestamp,
        snippet: videoBlog[42].snippet,
        img: videoBlog[42].img,
        type: "video"
    }, {
        title: "Leveraging GPT-4 for Website Generation",
        link: '/blog/gpt-4-website-generation',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog39}</MuiMarkdown>),
        url: "https://creataai.com/blog/gpt-4-website-generation",
        date: 1695189957,
        snippet: "Explore how the AI model GPT-4 can be effectively used for content creation and website generation, while discussing its benefits, limitations, and potential.",
        img: 'https://source.unsplash.com/macbook-pro-on-top-of-brown-wooden-table-during-daytime-KiEiI2b9GkU',
        type: "article",
    },

    {
        title: videoBlog[43].title,
        link: `/blog/video/${videoBlog[43].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[43].title,
        url: videoBlog[43].videoLink,
        date: videoBlog[43].timestamp,
        snippet: videoBlog[43].snippet,
        img: videoBlog[43].img,
        type: "video"
    },
    {
        title: videoBlog[44].title,
        link: `/blog/video/${videoBlog[44].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[44].title,
        url: videoBlog[44].videoLink,
        date: videoBlog[44].timestamp,
        snippet: videoBlog[44].snippet,
        img: videoBlog[44].img,
        type: "video"
    },
    {
        title: "Understanding AutoGpt and How to Create One: AutoGPT, gptAgent, Private GPT",
        link: '/blog/understanding-autogpt-creating-guide',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog40}</MuiMarkdown>),
        url: "https://creataai.com/blog/understanding-autogpt-creating-guide",
        date: 1697768865,
        snippet: "Get a grasp on AutoGpt, its role in AI technology, and a step-by-step guide on how to create one. Discover the benefits and challenges associated with AutoGpt.",
        img: 'https://source.unsplash.com/a-computer-chip-that-is-glowing-green-in-the-dark-dCvqMHRUIhY',
        type: "article",
    },

    {
        title: videoBlog[45].title,
        link: `/blog/video/${videoBlog[45].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[45].title,
        url: videoBlog[45].videoLink,
        date: videoBlog[45].timestamp,
        snippet: videoBlog[45].snippet,
        img: videoBlog[45].img,
        type: "video"
    },
    {
        title: videoBlog[46].title,
        link: `/blog/video/${videoBlog[46].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[46].title,
        url: videoBlog[46].videoLink,
        date: videoBlog[46].timestamp,
        snippet: videoBlog[46].snippet,
        img: videoBlog[46].img,
        type: "video"
    }, {
        title: "Mastering Article Rewriting with ChatGPT: A Comprehensive Guide",
        link: '/blog/chatgpt-article-rewriting-guide',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog41}</MuiMarkdown>),
        url: "https://creataai.com/blog/chatgpt-article-rewriting-guide",
        date: 1698142512,
        snippet: "Discover how to use the AI-powered tool, chatGPT, for efficient and effective article rewriting. Includes a step-by-step guide and handy tips.",
        img: 'https://source.unsplash.com/silver-macbook-beside-space-gray-iphone-6-and-clear-drinking-glass-on-brown-wooden-top-n8Qb1ZAkK88',
        type: "tutorial",
    },

    {
        title: videoBlog[47].title,
        link: `/blog/video/${videoBlog[47].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[47].title,
        url: videoBlog[47].videoLink,
        date: videoBlog[47].timestamp,
        snippet: videoBlog[47].snippet,
        img: videoBlog[47].img,
        type: "video"
    },
    {
        title: videoBlog[48].title,
        link: `/blog/video/${videoBlog[48].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[48].title,
        url: videoBlog[48].videoLink,
        date: videoBlog[48].timestamp,
        snippet: videoBlog[48].snippet,
        img: videoBlog[48].img,
        type: "video"
    }, {
        title: "Step-by-Step Guide: How to Use GPT-4 for Article Rewriting",
        link: '/blog/tutorial-using-gpt4-article-rewriting',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog42}</MuiMarkdown>),
        url: "https://creataai.com/blog/tutorial-using-gpt4-article-rewriting",
        date: 1695637759,
        snippet: "Learn how to utilize the powerful AI model, GPT-4 by OpenAI, to rewrite and optimize your articles. Includes crafting effective prompts and examples.",
        img: 'https://source.unsplash.com/person-using-macbook-pro-npxXWgQ33ZQ',
        type: "tutorial",
    },

    {
        title: videoBlog[49].title,
        link: `/blog/video/${videoBlog[49].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[49].title,
        url: videoBlog[49].videoLink,
        date: videoBlog[49].timestamp,
        snippet: videoBlog[49].snippet,
        img: videoBlog[49].img,
        type: "video"
    },
    {
        title: videoBlog[50].title,
        link: `/blog/video/${videoBlog[50].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[50].title,
        url: videoBlog[50].videoLink,
        date: videoBlog[50].timestamp,
        snippet: videoBlog[50].snippet,
        img: videoBlog[50].img,
        type: "video"
    }, {
        title: "Exploring the Structure and Functionality of GPT and LLAMA",
        link: '/blog/gpt-llama-architecture-software-components',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog43}</MuiMarkdown>),
        url: "https://creataai.com/blog/gpt-llama-architecture-software-components",
        date: 1698475733,
        snippet: "In-depth exploration into the architectural designs and software components of two influential language models - Generative Pre-training Transformer (GPT) and LAnguage Model Analysis (LLAMA). Understand their functionality, operation and role in natural language understanding tasks.",
        img: 'https://source.unsplash.com/a-square-object-with-a-knot-on-it-fluoEjpdj60',
        type: "article",
    },

    {
        title: videoBlog[51].title,
        link: `/blog/video/${videoBlog[51].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[51].title,
        url: videoBlog[51].videoLink,
        date: videoBlog[51].timestamp,
        snippet: videoBlog[51].snippet,
        img: videoBlog[51].img,
        type: "video"
    },
    {
        title: videoBlog[52].title,
        link: `/blog/video/${videoBlog[52].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[52].title,
        url: videoBlog[52].videoLink,
        date: videoBlog[52].timestamp,
        snippet: videoBlog[52].snippet,
        img: videoBlog[52].img,
        type: "video"
    }, {
        title: "Step-by-step Guide for Installing and Running GPT4All",
        link: '/blog/Step-by-step-Guide-for-Installing-GPT4All',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog44}</MuiMarkdown>),
        url: "https://creataai.com/blog/Step-by-step-Guide-for-Installing-GPT4All",
        date: 1698544585,
        snippet: "GPT4All is an ecosystem to train and deploy powerful and customized large language models that run locally on consumer grade CPUs.",
        img: 'https://easywithai.com/storage/2023/04/GPT4all.png',
        type: "article",
    },

    {
        title: videoBlog[53].title,
        link: `/blog/video/${videoBlog[53].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[53].title,
        url: videoBlog[53].videoLink,
        date: videoBlog[53].timestamp,
        snippet: videoBlog[53].snippet,
        img: videoBlog[53].img,
        type: "video"
    },
    {
        title: videoBlog[54].title,
        link: `/blog/video/${videoBlog[54].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[54].title,
        url: videoBlog[54].videoLink,
        date: videoBlog[54].timestamp,
        snippet: videoBlog[54].snippet,
        img: videoBlog[54].img,
        type: "video"
    }, {
        title: "AI Music Composer: Terms and Guide",
        link: '/blog/ai-music-composer-terms-and-guide',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog45}</MuiMarkdown>),
        url: "https://creataai.com/blog/ai-music-composer-terms-and-guide",
        date: 1698727890,
        snippet: "Example types of Synth solo that you could enter as the prompt to generate Ai music.",
        img: 'https://source.unsplash.com/selective-focus-silhouette-photography-of-man-playing-red-lighted-dj-terminal-YrtFlrLo2DQ',
        type: "article",
    },
    {
        title: videoBlog[55].title,
        link: `/blog/video/${videoBlog[55].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[55].title,
        url: videoBlog[55].videoLink,
        date: videoBlog[55].timestamp,
        snippet: videoBlog[55].snippet,
        img: videoBlog[55].img,
        type: "video"
    },
    {
        title: videoBlog[56].title,
        link: `/blog/video/${videoBlog[56].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[56].title,
        url: videoBlog[56].videoLink,
        date: videoBlog[56].timestamp,
        snippet: videoBlog[56].snippet,
        img: videoBlog[56].img,
        type: "video"
    }, {
        title: "How to Install & Use PrivateGPT",
        link: '/blog/How-to-Install-and-Use-PrivateGPT',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog46}</MuiMarkdown>),
        url: "https://creataai.com/blog/How-to-Install-and-Use-PrivateGPT",
        date: 1698794718,
        snippet: "PrivateGPT allows you to chat with your local documents, pdfs, cvs, etc.. The chat result is more relevant and private. It allows you to localize GPT and no need to go to openAI which incures additional cost (embedding).",
        img: 'https://source.unsplash.com/a-computer-chip-with-the-word-gat-printed-on-it-Fc1GBkmV-Dw',
        type: "tutorial",
    },
    {
        title: videoBlog[57].title,
        link: `/blog/video/${videoBlog[57].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[57].title,
        url: videoBlog[57].videoLink,
        date: videoBlog[57].timestamp,
        snippet: videoBlog[57].snippet,
        img: videoBlog[57].img,
        type: "video"
    }, {
        title: "Understanding the Concept of Embedding in LLAM",
        link: '/blog/Embedding-in-LLAMA-explained',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog47}</MuiMarkdown>),
        url: "https://creataai.com/blog/Embedding-in-LLAMA-explained",
        date: 1699350148,
        snippet: "Delve into the concept of embedding, explore the features of LLAMA, and understand how the intersection of these two can revolutionize various industries.",
        img: 'https://source.unsplash.com/a-pile-of-plastic-letters-and-numbers-on-a-pink-and-blue-background-5u6bz2tYhX8',
        type: "tutorial",    
    }, {
        title: videoBlog[58].title,
        link: `/blog/video/${videoBlog[58].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[58].title,
        url: videoBlog[58].videoLink,
        date: videoBlog[58].timestamp,
        snippet: videoBlog[58].snippet,
        img: videoBlog[58].img,
        type: "video"
    }, {
        title: "Comparing Local GPT Solutions: AutoGPT, anyhtingLLM, GPT4All, PrivateGPT",
        link: '/blog/Comparing-Local-GPT-Solutions_AutoGPT-anyhtingLLM-GPT4All-PrivateGPT',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog48}</MuiMarkdown>),
        url: "https://creataai.com/blog/Comparing-Local-GPT-Solutions_AutoGPT-anyhtingLLM-GPT4All-PrivateGPT",
        date: 1699425621,
        snippet: "The author has tested some of the open sourced packages and would like to share his experience in installing and running each one of them.",
        img: 'https://creataai.com/img/octopus_alien.jpg',
        type: "tutorial",
    },
    {
        title: videoBlog[59].title,
        link: `/blog/video/${videoBlog[59].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[59].title,
        url: videoBlog[59].videoLink,
        date: videoBlog[59].timestamp,
        snippet: videoBlog[59].snippet,
        img: videoBlog[59].img,
        type: "video"
    }, {
        title: "Unleashing the Power of GPT Prompts for Effective Business Marketing",
        link: '/blog/mastering-gpt-prompts-business-marketing',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog49}</MuiMarkdown>),
        url: "https://creataai.com/blog/mastering-gpt-prompts-business-marketing",
        date: 1699615526,
        snippet: "Understand GPT prompts and how to effectively use them for business marketing. Learn the best practices to optimize your content creation process.",
        img: 'https://source.unsplash.com/person-using-laptop-on-white-wooden-table-iusJ25iYu1c',
        type: "tutorial",
    },
    {
        title: videoBlog[60].title,
        link: `/blog/video/${videoBlog[60].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[60].title,
        url: videoBlog[60].videoLink,
        date: videoBlog[60].timestamp,
        snippet: videoBlog[60].snippet,
        img: videoBlog[60].img,
        type: "video"
    }, {
        title: "An In-Depth guide to Processing CVS Files and Spreadsheets with GPT-4",
        link: '/blog/processing-cvs-files-spreadsheets-gpt4',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog50}</MuiMarkdown>),
        url: "https://creataai.com/blog/processing-cvs-files-spreadsheets-gpt4",
        date: 1699933125,
        snippet: "Uncover the potential of GPT-4 in processing CSV files & managing spreadsheets. Easy step-by-step tutorials, from setting up to troubleshooting.",
        img: 'https://source.unsplash.com/laptop-computer-on-glass-top-table-hpjSkU2UYSU',
        type: "tutorial",
    }, {
        title: videoBlog[61].title,
        link: `/blog/video/${videoBlog[61].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[61].title,
        url: videoBlog[61].videoLink,
        date: videoBlog[61].timestamp,
        snippet: videoBlog[61].snippet,
        img: videoBlog[61].img,
        type: "video"
    }, {
        title: "Step-by-Step Guide to Creating a ChatGPT Plugin",
        link: '/blog/create-chatgpt-plugin-guide',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog51}</MuiMarkdown>),
        url: "https://creataai.com/blog/create-chatgpt-plugin-guide",
        date: 1700008280,
        snippet: " Learn how to create a custom ChatGPT plugin step by step, from understanding the basics to deployment and marketing your tool.",
        img: 'https://source.unsplash.com/a-couple-of-tents-sitting-on-top-of-a-lush-green-field-7NZPy5YP6oE',
        type: "tutorial",
    },
    {
        title: videoBlog[62].title,
        link: `/blog/video/${videoBlog[62].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[62].title,
        url: videoBlog[62].videoLink,
        date: videoBlog[62].timestamp,
        snippet: videoBlog[62].snippet,
        img: videoBlog[62].img,
        type: "video"
    }, {
        title: "Maximize Your Chatbot's Smarts with Vector Databases",
        link: '/blog/vector-database-chatbot-intelligence',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog52}</MuiMarkdown>),
        url: "https://creataai.com/blog/vector-database-chatbot-intelligence",
        date: 1700095548,
        snippet: "Discover how vector databases can boost the intelligence of chatbots like ChatGPT, enhancing user interactions with speed and precision.",
        img: 'https://creataai.com/img/image-orange-puzzle-box.jpg',
        type: "article",
    },
    {
        title: videoBlog[63].title,
        link: `/blog/video/${videoBlog[63].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[63].title,
        url: videoBlog[63].videoLink,
        date: videoBlog[63].timestamp,
        snippet: videoBlog[63].snippet,
        img: videoBlog[63].img,
        type: "video"
    }, {
        title: "Top 5 Vector Databases for Chatbot Applications: Features and Analysis",
        link: '/blog/top-5-vector-database-chatbot-application',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog53}</MuiMarkdown>),
        url: "https://creataai.com/blog/top-5-vector-database-chatbot-application",
        date: 1700283251,
        snippet: "Explore top vector databases for chatbot apps, their unique features, pros, and cons to enhance your chatbot's conversational abilities.",
        img: 'https://creataai.com/img/image-robot-working-onmars.jpg',
        type: "article",
    }, {
        title: videoBlog[64].title,
        link: `/blog/video/${videoBlog[64].title}`,
        icon: InfoOutlinedIcon,
        content: videoBlog[64].title,
        url: videoBlog[64].videoLink,
        date: videoBlog[64].timestamp,
        snippet: videoBlog[64].snippet,
        img: videoBlog[64].img,
        type: "video"
    }, {
        title: "Build a Smart AI Calendar with GPT-4 Tech",
        link: '/blog/ai-calendar-gpt4',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog54}</MuiMarkdown>),
        url: "https://creataai.com/blog/ai-calendar-gpt4",
        date: 1700532491,
        snippet: "Learn how to create an intelligent AI calendar using the latest GPT-4 technology, integrating advanced features for streamlined scheduling.",
        img: 'https://creataai.com/img/image-futuristic-tablet.jpg',
        type: "article",
    },
    {
        title: "Embracing the Future with Autogen Multi AI Agent Framework",
        link: '/blog/autogen-ai-agent-framework',
        icon: InfoOutlinedIcon,
        content: (< MuiMarkdown overrides={styles}>{blog55}</MuiMarkdown>),
        url: "https://creataai.com/blog/autogen-ai-agent-framework",
        date: 1707722619,
        snippet: "Designed as a unified multi-agent conversation framework, Autogen serves as a high-level abstraction tool for foundation models, demonstrating a distinctive edge in its highly customizable capabilities and robust support for large language model-based applications.",
        img: 'https://miro.medium.com/v2/resize:fit:1400/1*pEQHyBDenkQgYzDxRp8Aew.png',
        type: "article",
    },]

export  {
    blogPosts,
    blogTypes
}