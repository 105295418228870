import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import GenericCard from "../Components/Card";
import BuildIcon from "@mui/icons-material/Build";
import ComputerIcon from "@mui/icons-material/Computer";
import BarChartIcon from "@mui/icons-material/BarChart";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloudIcon from "@mui/icons-material/Cloud";
import MeassageIcon from "@mui/icons-material/Message";
import CancelIcon from "@mui/icons-material/Cancel";

// import styles of this component
import styles from "../App.module.css"

// import other components to use
import Nav from "../Components/Nav/Nav";
// import something from react packages
import { Icon, SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";
import Prompt from "../Jsons/Prompt.json";
import API from "../API"
import { Grid, Typography, Divider, Link, Button } from "@mui/material";
import Item from "@mui/material/Grid"
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { StarBorderIcon, FavoriteIcon, SubscriptionsRounded } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Container, stepButtonClasses } from "@mui/material";
import AppPlayStore from "../Components/AppPlayStore";
import { MailLockOutline, MailOutline } from "@mui/icons-material";
// import json files 
import images from "../Jsons/Images.json"
import SignupForm from "../Components/SignupForm";


function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const theme = createTheme({
    palette: {
        mode: 'dark',
    }
},
);

const iconSize = 30;

const features = [
    {
        color: "#00C853",
        headline: "Controlnet Canny",
        text: "Use canny model for image edge and feature detection, then employ stable diffusion for final art generation.",
        icon: <BuildIcon style={{ fontSize: iconSize }} />,
        mdDelay: "0",
        smDelay: "0",
        image: "img/creataai_controlnet_canny_example3.png"
    },
    {
        color: "#6200EA",
        headline: "Controlnet + Deliberate",
        text: "Use controlnet canny for image detection then employ Deliberate model based on stable diffusion for final art generation.",
        icon: <CalendarTodayIcon style={{ fontSize: iconSize }} />,
        mdDelay: "200",
        smDelay: "200",
        image: "img/creataai_controlnet_deliberate_example.png"
    },
    {
        color: "#0091EA",
        headline: "Controlnet Structure",
        text: "The model makes use of hough image detection algorithm which is good at detecting structural elements in the input image. It's great in generating interior design or architectural images.",
        icon: <MeassageIcon style={{ fontSize: iconSize }} />,
        mdDelay: "400",
        smDelay: "0",
        image: "img/creataai_controlnet_hough_example.png"
    },
    {
        color: "#d50000",
        headline: "Controlnet HED",
        text: "HED creates image that is faithful to the input image or photo. One can use it to tur your photo into a fantacy art.",
        icon: <ComputerIcon style={{ fontSize: iconSize }} />,
        mdDelay: "0",
        smDelay: "200",
        image: "img/creataai_controlnet_hed_example2.png"
    },
    {
        color: "#d50000",
        headline: "Controlnet Realistic",
        text: "This model combines the power of controlnet and realistic vision to create amazing art effects.",
        icon: <ComputerIcon style={{ fontSize: iconSize }} />,
        mdDelay: "0",
        smDelay: "200",
        image: "img/creataai_controlnet_realistic_example.png"
    },]
// App component
const photoArtStyleGuide = (props) => {
    const [imageArray, setImageArray] = useState([])
    const [promptText, setPrompt] = useState('')
    const history = useHistory()
    const { prompt } = (props.location && props.location.state) || {};

    async function search() {
        let prompt = document.getElementById("prompt").value
        if (prompt == null || prompt.size <= 0) return
        let result = await API.getImagesForPrompt(prompt)
        setImageArray(result.images)
        console.log(result)
    }

    function gotoCreate(promptText) {
        history.push('/create');
    }

    function showMore() {
        var x = document.getElementById("showMore");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
        x.scrollIntoView()

    }
    let posts = ["This is the support page"]

    async function searchClick(promptText) {
        document.getElementById("prompt").value = promptText
        let result = await API.getImagesForPrompt(promptText)
        setImageArray(result.images)
    }

    return (
        <>
            <Nav />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Grid container spacing={5} sx={{ mt: 3 }}>
                        {/*<Grid item>
                            <AppPlayStore />
    </Grid> */}
                        {/*} <Grid sx={{ml: 3}} item><img src='img/create2.jpg' width={257} height={556}></img>
              <img src='img/details.jpg' width={257} height={556}></img></Grid>
            */}
                        <Grid
                            item
                            xs={12}
                            md={10}
                        >
                            <Typography variant="h2" gutterBottom>
                               Controlnet Image To Art Model Guide
                            </Typography>
                            <Item>
                                <img src="img/creataai_controlnet_deliberate.png" alt="ai art app"></img>
                            </Item>
                            <Typography variant="h6">Prompt used to generate the above sample: </Typography>
                            <Typography sx={{ fontStyle: 'italic', m: 1 }}>"RAW photo, portrait photo of 30 y.o woman queen, pale skin, slim body, (high detailed skin:1.2), background ocean, 8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3"</Typography>
                            <Typography variant="h5" gutterBottom>
                                <br/>Introducing controlnet models that combined with stable diffusion and other intermediate AI models to support generating art from a photo or image
                            </Typography>
                            <Typography variant="h6">Creata AI Controlnet+Stable Diffusion Models:<br/>
                            - Canny: combination of stable diffusion + controlnet <br/>
                            - Deliberate: it's great at generating an art, avatar or photo based on a portrait of a person. Compare it with Lensa.<br />
                            - Struture: great at generating interior design images by detecting strutural information from the image<br />
                                - HED: Controlnet with HED image detection<br />
                                - Realistic: Generate realistic art images<br />
                            </Typography>
                            {/*<GenericCard></GenericCard>*/}
                            <div style={{ backgroundColor: "#000000" }}>
                                <div className="container-fluid lg-p-top">
                                    <Typography variant="h3" align="center" className="lg-mg-bottom" style={{ fontWeight: 700 }}>
                                        Models
                                    </Typography>
                                    <div className="container-fluid">
                                        <Grid container spacing={10}>
                                            {features.map((element) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={8}
                                                    data-aos="zoom-in-up"
                                                    data-aos-delay={element.smDelay}
                                                    key={element.headline}
                                                >
                                                    {/*<FeatureCard
                                                        Icon={element.icon}
                                                        color={element.color}
                                                        headline={element.headline}
                                                        text={element.text}
                                            />*/}   
                                                    <img src={element.image} />
                                                    <Typography variant="h4" paragraph>
                                                        {element.headline}
                                                    </Typography>
                                                    <Typography variant="h5" color="textSecondary">
                                                        {element.text}
                                                    </Typography>

                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </div>
                            </div>

                            <Divider />
                            <Divider />
                            <Typography variant="h4" component="h4" gutterBottom sx={{ marginTop: "10px" }}>Parameters</Typography>
                            <Typography variant="h5" component="h5">
                                Some of the models provide input Parameters for fine tunning image generation. Here are some of the most common model parameters you should know about:<br /></Typography>
                            <Typography sx={{ marginTop: "10px" }}>
                                <ul style={{ margin: "10px" }}>
                                    <li style={{ marginTop: "10px" }}><b>Scale/Guidance</b>: a value between 0.1 - 30. Used to specify how closely the generation should be to the prompt's description. The larger the value closer the result image to the prompt</li>
                                    <li style={{ marginTop: "30px" }}><b>weight/stength</b>: a value between 0.01 - 2, the weight that controlnet model influences art generation</li>
                                    <li style={{ marginTop: "30px" }}><b>distance threshould</b>: a value in the image the model should use to detect input image features. If you want it to pick image elements farther away, use a larger value.</li>
                                    <li style={{ marginTop: "30px" }}><b>Color value threshould</b>: the image colors that are larger than the specified value will be detected. The larger the value is, the more brighter part of the input image will be used in the final art image creation.</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Divider></Divider>
                        <Typography variant="h4" gutterBottom sx={{ marginTop: '20px', marginLeft: '40px' }} >
                            <Link id="turn-nsfw-off" underline="none">macOS App Screenshots </Link>
                        </Typography>
                        <Typography>
                        <h5 style={{
                            color: 'white', fontSize: '20', position: 'absolute', left: '0px', bottom: '-30px'
                        }}></h5>
                            <a href='https://creataai.com/macOSApp'><img src='img/macos2.png' width={'50%'} style={{ borderRadius: '10px', boader: '4px solid #ffffff' }} ></img></a>
                        </Typography>
                        <Grid sx={{ marginTop: '20px', marginLeft: '20px' }} container spacing={2} columns={20}>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/c.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/creating50AIImages.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/a1.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/e.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/j.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/g.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/i.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/m.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/p.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>

        </>
    )
}

export default photoArtStyleGuide