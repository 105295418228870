import { useMemo } from 'react';
import { Carousel } from 'react-carousel-minimal';
import Header from './Components/Header/Header';
import Nav from "./Components/Nav/Nav";
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function ArtShow(props) {
    const { images } = (props.location && props.location.state) || {};
    var data = []

    useMemo(()=>{
        for (var i = 0; i < images.length; i++) {
            data.push({ image: images[i].src, caption: images[i].prompt })
        }
    })



    const captionStyle = {
        fontSize: '0.75em',
        fontWeight: 'regular',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }
    return (
        <>
            <Nav />
            <div className="flex justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
                <div><IconButton aria-label="back" color='white'><ArrowBackIosIcon/></IconButton></div>    
            <div style={{ textAlign: "center" }}>
                <h2>Stable Diffusion</h2>
                <p></p>
                <div style={{
                    padding: "0 20px"
                }}>
                    <Carousel
                        data={data}
                        time={5000}
                        width="850px"
                        height="500px"
                        captionStyle={captionStyle}
                        radius="10px"
                        slideNumber={true}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={true}
                        dots={false}
                        pauseIconColor="white"
                        pauseIconSize="40px"
                        slideBackgroundColor="darkgrey"
                        slideImageFit="cover"
                        thumbnails={false}
                        thumbnailWidth="100px"
                        style={{
                            textAlign: "center",
                            maxWidth: "850px",
                            maxHeight: "500px",
                            margin: "40px auto",
                        }}
                    />
                </div>
                </div>
                </div>
        </>
    );
}

export default ArtShow;