import styles from "./BrickLayout.module.css"

const BrickLayout = () => {
  return (
    <div className={styles["brick-layout"]}>
      <div className={styles["brick-column"]}>
        <img src="img/cover_right/1.jpg" alt="stable diffusion" />
        <img src="img/cover_right/2.jpg" alt="stable diffusion" />
        <img src="img/cover_right/3.jpg" alt="stable diffusion" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/cover_right/4.jpg" alt="stable diffusion" />
        <img src="img/cover_right/5.jpg" alt="stable diffusion" />
        <img src="img/cover_right/6.jpg" alt="stable diffusion" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/cover_right/7.jpg" alt="stable diffusion" />
        <img src="img/cover_right/8.jpg" alt="stable diffusion" />
        <img src="img/cover_right/9.jpg" alt="stable diffusion" />
      </div>
    </div>
  )
}

export default BrickLayout