import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import GenericCard from "../Components/Card";
import RemoveIcon from '@mui/icons-material/Remove';

// import styles of this component
import styles from "../App.module.css"

// import other components to use
import Nav from "../Components/Nav/Nav";
// import something from react packages
import { Icon, SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";
import Prompt from "../Jsons/Prompt.json";
import API from "../API"
import { Grid, Typography, Divider, Link, Button, List, ListItemText, ListItemIcon, ListItem,ListItemButton } from "@mui/material";
import Item from "@mui/material/Grid"
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { StarBorderIcon, FavoriteIcon, SubscriptionsRounded } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Container, stepButtonClasses } from "@mui/material";
import AppPlayStore from "../Components/AppPlayStore";
import { MailLockOutline, MailOutline } from "@mui/icons-material";
// import json files 
import images from "../Jsons/Images.json"
import SignupForm from "../Components/SignupForm";
import MacOSVersions from "../Components/MacOSVersions";

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const theme = createTheme({
    palette: {
        mode: 'dark',
    }
},
);

// App component
const macOSApp = (props) => {
    const [imageArray, setImageArray] = useState([])
    const [promptText, setPrompt] = useState('')
    const history = useHistory()
    const { prompt } = (props.location && props.location.state) || {};

    async function search() {
        let prompt = document.getElementById("prompt").value
        if (prompt == null || prompt.size <= 0) return
        let result = await API.getImagesForPrompt(prompt)
        setImageArray(result.images)
        console.log(result)
    }

    function gotoCreate(promptText) {
        history.push('/create');
    }

    function showMore() {
        var x = document.getElementById("showMore");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
        x.scrollIntoView()

    }
    let posts = ["This is the support page"]

    async function searchClick(promptText) {
        document.getElementById("prompt").value = promptText
        let result = await API.getImagesForPrompt(promptText)
        setImageArray(result.images)
    }

    return (
        <>
            <Nav />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Grid container spacing={5} sx={{ mt: 3 }}>
                        <Grid item>
                            <AppPlayStore />
                        </Grid>
                        {/*} <Grid sx={{ml: 3}} item><img src='img/create2.jpg' width={257} height={556}></img>
              <img src='img/details.jpg' width={257} height={556}></img></Grid>
            */}
                        <Grid
                            item
                            xs={12}
                            md={10}
                        >
                            <Typography variant="h2" gutterBottom sx={{marginBottom:5}}>
                                Creata AI App for Android
                            </Typography>
                            <Grid container spacing={1}>
                            <Item sm={4}>
                                    <img src="img/ai_art_appscreen.jpg" alt="ai art app" width={'50%'}></img>
                            </Item>
                            <Item sm={4}>
                                <img src="img/ai_avatar_appscreen.jpg" alt="ai art app" width={'50%'}></img>
                            </Item>
                            <Item sm={4}>
                                <img src="img/ai_design_appscreen.jpg" alt="ai art app" width={'50%'}></img>
                                </Item>
                                </Grid>
                            <Link href='/creataAppV4.4.4Build54-release.apk'>Download APK - Version 4.4.4 Build 54</Link>
                            <Typography variant="h6">Also available in Google Play Store: <Link href='https://play.google.com/store/apps/details?id=com.creataai.creata&hl=en_US&gl=US' target='_blank'>Play Store</Link></Typography>
                            <Typography variant="h2" gutterBottom sx={{marginTop:10, marginBottom:5}}>
                                Creata AI App for macOS
                            </Typography>
                            <Item>
                                <img src="img/creataai_macos_screenshot.jpg" alt="ai art app"></img>
                            </Item>
                            <Typography variant="h5" gutterBottom sx={{margin:5}}>
                                Create unlimited AI art on your own macbook
                            </Typography>
                            <Typography variant="h5">What's New?</Typography>
                            <img src="img/creataai_controlnet_deliberate.png" alt="ai art app"></img>
                            <List>
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemIcon><RemoveIcon/></ListItemIcon>
                                        <ListItemText primary="5 Image-to-image AI models"></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemIcon><RemoveIcon /></ListItemIcon>
                                        <ListItemText primary="24 predefined models for high quality art generation"></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemIcon><RemoveIcon /></ListItemIcon>
                                        <ListItemText primary="Over 600 art styles"></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemIcon><RemoveIcon /></ListItemIcon>
                                        <ListItemText primary="High resolution image enargement and face correction"></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemIcon><RemoveIcon /></ListItemIcon>
                                        <ListItemText primary="GAN AI model for image unblurring"></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Typography variant="h5">Latest Version</Typography>
                            <Typography variant="h6">Download the latest v3.1 here: <a href='https://drive.google.com/file/d/13Ynmox-YNRkIupM5AOMAqec0wkSfWy6a/view?usp=share_link'>Craeta AI Art & ChatGPT App</a></Typography>
                            <Item alignContent='center'>
                                <a href='https://drive.google.com/file/d/1TyT30jLA9v0erDv2CnRPY8pg_cNO-Bpv/view?usp=share_link'><img src="img/download.png" marginleft={250} alt="ai art app" width={100} height={100}></img>Version 3.1 with Image-to-image Models</a>
                            </Item>
                            <Typography variant="h5">Older version</Typography>
                            <Typography variant="h6">Download the latest v2.2 here: <a href='https://drive.google.com/file/d/1cP-s7g87nT9ipwdggaZFkb2_DGjTxP-a/view?usp=share_link'>Craeta AI Art & ChatGPT App</a></Typography>
                            <Item alignContent='center'>
                                <a href='https://drive.google.com/file/d/1TyT30jLA9v0erDv2CnRPY8pg_cNO-Bpv/view?usp=share_link'><img src="img/download.png" width="100" height="100" marginleft={250} alt="ai art app"></img>Version 2.1 with ChatGPT</a>
                            </Item>
                            {/*<GenericCard></GenericCard>*/}

                            <Typography variant="h6">Download link for version 1.3.1 on Apple App Store: <a href='https://apps.apple.com/us/app/creata-ai-art-artist/id1659088194'>Apple App Store</a></Typography>
                            <Typography variant="h6" alignItems="center">The macOS version of the app is the 1st commercial AI art app that let's you create AI art on your own macbook device. The Ai model runs locally on your macbook and churns out art and images every 20 seconds on a M1 macbook. </Typography>
                            <Divider />
                            <MacOSVersions />
                            <Button variant="outlined" onClick={() => showMore()}>Learn More About Creating AI Art On MacBook</Button>
                            <Typography variant="h6" component="p" id="showMore" style={{ display: 'none' }}>Running a stable diffusion AI model on an Apple M1 MacBook requires several steps, including installing the necessary software, setting up the development environment, and running the model itself.
                                <br /><br />
                                First, you will need to select a stable diffusion AI model that is compatible with the Apple M1 processor. This may involve choosing a model that has been specifically optimized for the M1 architecture, or one that has been developed using software tools that are compatible with the M1 processor.
                                <br /><br />

                                Next, you will need to install the necessary software libraries and development tools that are required to run the AI model. This may involve installing Python, TensorFlow, PyTorch, or other libraries that are commonly used in AI development.
                                <br /><br />

                                Once you have set up the development environment, you can begin training and testing the stable diffusion AI model. This may involve using a set of training data to optimize the model's parameters and tune its performance, or running the model on a set of test data to evaluate its accuracy and effectiveness.
                                <br /><br />

                                One potential advantage of using an Apple M1 MacBook for AI development is that the M1 processor is designed to provide high performance and energy efficiency, which can be particularly useful when working with large datasets or computationally intensive AI models. Additionally, the M1 processor is optimized for running macOS, which provides a familiar and easy-to-use development environment for many developers.
                                <br /><br />

                                Overall, running a stable diffusion AI model on an Apple M1 MacBook can be a powerful and efficient way to develop and deploy AI applications in a variety of fields, from physics and chemistry to engineering and environmental science.
                            </Typography>
                            <Divider />
                            <Typography variant="h4" component="h4" gutterBottom>FAQ</Typography>
                            <Typography variant="h6" gutterBottom>
                                <Link id="benefits" underline="none">How have we done it? </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                                - Stable diffusion Ai model is converted into a model that runs efficiently on Apple devices<br />
                                - Art is created in about ~20 seconds after the model is loaded and initialized <br/>
                                - The speed is faster than Apple's own coreML based model <br />
                                - It churns out 50 images a time for an art prompt and let you pick the best you like from it<br />
                                - Create art image at your device that nobody else ever knows it<br/>
                            </Typography>

                            <Typography variant="h6" gutterBottom>
                                <Link id="turn-nsfw-off" underline="none">Does it have NSFW filter? </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                                Yes, a NSFW filter is applied to the images generated locally except the Pro version. The created images run through a machine learning model that is trained to detect NSFW art.
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                <Link id="turn-nsfw-off" underline="none">I am a professional Ai artist, I want to turn off the NSFW filter. What do I do?</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                                Please subscribe to the Pro version (see above).
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                <Link id="turn-nsfw-off" underline="none">Will you support future generative AI models, such as Stable difussion 2.1?</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                                We do continue update our software and models on macbook to support the latest AI models. Since most AI artists prefer Stable Diffusion 1.5 vs. 2.1, we are keeping 1.5 in the app.
                            </Typography>

                            {/*<SignupForm />*/}
                        </Grid>
                        <Divider></Divider>
                        <Typography variant="h4" gutterBottom sx={{ marginTop: '20px', marginLeft: '40px' }} >
                            <Link id="turn-nsfw-off" underline="none">More macOS App Screenshots </Link>
                        </Typography>
                        <Grid sx={{ marginTop: '20px', marginLeft:'20px' }} container spacing={2} columns={20}>
                            <Grid item >
                            <Item>
                                    <img src='img/macos/screenshots/c.jpeg' width={320} height={240}></img>
                                </Item>
                                </Grid>
                            <Grid item >
                            <Item>
                                    <img src='img/macos/screenshots/creating50AIImages.jpeg' width={320} height={240}></img>
                                </Item>
                                </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/a1.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/e.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/j.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/g.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/i.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/m.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                            <Grid item >
                                <Item>
                                    <img src='img/macos/screenshots/p.jpeg' width={320} height={240}></img>
                                </Item>
                            </Grid>
                                                        
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>

        </>
    )
}

export default macOSApp