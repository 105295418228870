import { useHistory } from 'react-router-dom'

// import styles of this component
import styles from "./Header.module.css"

// import other components
import ContainerCard from '../ContainerCard/ContainerCard';
import Nav from "../Nav/Nav"
import BrickLayout from "../BrickLayout/BrickLayout";
import HeaderBoxes from "./HeaderBoxes/HeaderBoxes";

// import something from react packages
import { SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";

// import jsons
import JsonHeader from '../../Jsons/HeaderBoxes.json';
import AppPlayStore from '../AppPlayStore';
import { Button, Container, Grid, Typography } from '@mui/material';


// Header component
const Header = () => {
  const history = useHistory()

  function showMore() {
    var x = document.getElementById("showMore");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }

  }

  function gotoExplore() {
    console.log(document.getElementById("promptInput").value)
    history.push({ pathname: '/explore', state: { prompt: document.getElementById("promptInput").value } })
  }
  return (
    <header className={`${styles.header} flex justify-content-center`}>
        <ContainerCard className="flex flex-column">
          <div className={styles["blur-circle-shape"]}></div>

          <Nav />
          <BrickLayout />
          
          <div className={`${styles["headings-header"]} flex justify-content-center flex-column `}>
          {/*<h2 className={styles["heading-header-title"]}>Create your dreams with Creata AI </h2>*/}
            <h1 className={styles["heading-header-second-title"]}>
            Bring Generative AI <br />
               to your finger tips
            </h1>
            
            <div className={`${styles["search-bar"]} flex align-items-center`}>
              <SearchNormal1 size="30" color="var(--white-100)"/>
              <input type="text" id="promptInput" className={styles["search-input"]} placeholder="Enter text to find dreams created by others" />
              <button onClick={gotoExplore} className={`${styles["search-btn"]} flex justify-content-center align-items-center`}>
                <Setting4 size="20" color="var(--dark-900)"/>
            </button>
          </div>
          <AppPlayStore/>
          {/* <HeaderBoxes titles_numbers={JsonHeader.informations} />*/}
        </div>
        </ContainerCard>
    </header>
  )
}

export default Header