import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';

// import styles of this component
import styles from "./App.module.css"

// import other components to use
import Nav from "./Components/Nav/Nav";
// import something from react packages
import { Icon, SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";
import Prompt from "./Jsons/Prompt.json";
import API from "./API"
import { Grid, Typography, Divider, Link } from "@mui/material";
import Item from "@mui/material/Grid"
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { StarBorderIcon, FavoriteIcon, SubscriptionsRounded } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Container, stepButtonClasses } from "@mui/material";
import AppPlayStore from "./Components/AppPlayStore";
import { MailLockOutline, MailOutline } from "@mui/icons-material";
// import json files 
import images from "./Jsons/Images.json"
import SignupForm from "./Components/SignupForm";


function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const theme = createTheme({
    palette: {
        mode: 'dark',
    }
},
);

// App component
const nsfw = (props) => {
    const [imageArray, setImageArray] = useState([])
    const [promptText, setPrompt] = useState('')
    const history = useHistory()
    const { prompt } = (props.location && props.location.state) || {};

    async function search() {
        let prompt = document.getElementById("prompt").value
        if (prompt == null || prompt.size <= 0) return
        let result = await API.getImagesForPrompt(prompt)
        setImageArray(result.images)
        console.log(result)
    }

    function gotoCreate(promptText) {
        history.push('/create');
    }

    let posts = ["This is the support page"]

    async function searchClick(promptText) {
        document.getElementById("prompt").value = promptText
        let result = await API.getImagesForPrompt(promptText)
        setImageArray(result.images)
    }

    return (
        <>
            <Nav />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Grid container spacing={5} sx={{ mt: 3 }}>
                        <Grid item>
                            <AppPlayStore />
                        </Grid>
                        {/*} <Grid sx={{ml: 3}} item><img src='img/create2.jpg' width={257} height={556}></img>
              <img src='img/details.jpg' width={257} height={556}></img></Grid>
            */}
                        <Grid
                            item
                            xs={12}
                            md={10}
                        >
                            <Typography variant="h4" gutterBottom>
                                About NSFW
                            </Typography>
                            <Typography variant="body2" alignItems="center">This version of macOS app released through Apple's app store has NSFW filter turned on to comply with apple's app store policy</Typography>
                            <Divider />
                            <Typography variant="h6" gutterBottom>
                                <Link id="benefits" underline="none">Why are we doing this? </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                                - Comply with app store requirment <br />
                                - As a protection for users who may be sensitive to NSFW contents the AI model could potential generate <br />
                            </Typography>

                            <Typography variant="h6" gutterBottom>
                                <Link id="turn-nsfw-off" underline="none">What if I need the filter turned off? </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ px: 4 }} paragraph="true">
                                Please add your email to the list. We will notify you the availability of the Pro version.
                            </Typography>
                            <SignupForm/>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
            {/*  <div className=" justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
          <Container>
              <div className={`${styles["gallery-setting"]} block justify-content-between align-items-center`}>
                <h1>Support</h1>
          </div>
          <div className=" justify-content-center">
            <img src='img/create2.jpg' width={257} height={556}></img>
            <img src='img/details.jpg' width={257} height={556}></img>
            </div>
                <AppPlayStore />
        </Container>
        <div xs={12} md={8} className=" justify-content-center">
          <Typography variant="h5" gutterBottom color={'white'}>Send Us Questions</Typography>
          <Typography variant="h6" gutterBottom color={'white'}><MailOutline />Support Email: <a href="sendTo@creataAi.com@gmail.com">creataAi.com@gmail.com</a></Typography>
          <Typography variant="h5" gutterBottom color={'white'}>FAQ</Typography>
        </div>
  
  </div> */}



        </>
    )
}

export default nsfw