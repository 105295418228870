import React from 'react';
import { createRoot } from 'react-dom/client'; // fro react 18
//import ReactDOM from 'react-dom'; // fro react 17
import App from './App';
import "./index.css"

// for react 18
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// for react 17
/*ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);*/