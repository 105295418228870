
import Player from "react-wavy-audio";


export default function AudioPlayer({ props }) {    
    const imgurl = props.imageurl ?? ''
    const audiourl = props.audiourl ?? ''

    const localStyles = {
        display: "flex",
        flexDirection: "row",
        maxWidth: "100vh",
        marginLeft: "auto",
        marginRight: "auto"
    }

    console.log(props)
    console.log(`audiourl: ${props.audiourl}`)
    return (
        <div >
            <Player
                
                imageUrl={imgurl}
                
                audioUrl={audiourl} //"https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3"
                waveStyles={{
                    cursorWidth: 1,
                    progressColor: "#ee3ec9",
                    responsive: true,
                    waveColor: "#e1e3f0",
                    cursorColor: "transparent",
                    barWidth: 1,
                }}
                zoom={5}
                containerStyles={{ maxWidth: "300vh" }}  //not working
            // waveJson
            // hideImage="true"
            // hideWave="true"
            // containerStyle={}
            />
        </div>
    );
}