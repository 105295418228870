import React from 'react';
import { NavLink, useHistory } from 'react-router-dom'; // Notice NavLink is imported here instead of Link
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

// import styles of this component
import styles from "./Nav.module.css";

// import other components
import Button from "../Elements/Button/Button";

// import other react pkg to use
import { HambergerMenu } from "iconsax-react";
import { func } from 'prop-types';

// Nav component
const Nav = () => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function create() {
        let path = `/create`;
        history.push(path);
    }

    function support() {
        let path = `/support`;
        history.push(path);
    }

    function blog() {
        let path = `/blog`;
        history.push(path);
    }

    function login() {

    }

    return (
        <nav className={`${styles.nav} flex align-items-center`}>
            <NavLink to='https://creataai.com' style={{display:'block'}}><img src='/img/Creata-Icon-App-64.png' width='40' height='40' style={{ marginRight: '10px' }} /></NavLink><h1 className={styles["nav-title"]}>Creata Ai</h1>
            <ul className={`flex align-items-center ${styles["navbar-nav"]}`}>
                <li className={styles["nav-item"]}>
                    <NavLink exact to="/" className={styles["nav-link"]} activeClassName={styles.active}>Home</NavLink>
                </li>
                <li className={styles["nav-item"]}>
                    <NavLink to="/feature" className={styles["nav-link"]} activeClassName={styles.active}>Feature</NavLink>
                </li>
                <li className={styles["nav-item"]}>
                    <NavLink to="/developer" className={styles["nav-link"]} activeClassName={styles.active}>Developer</NavLink>
                </li>
                <li className={styles["nav-item"]}>
                    <NavLink to="/macosapp" className={styles["nav-link"]} activeClassName={styles.active}>Download</NavLink>
                </li>
            </ul>
            <div className={`flex ${styles["navbar-buttons"]}`}>
                <Button theme="transparent" onClick={handleClick}>Login</Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>With login you can add credit and manage your project. Coming soon.</Typography>
                </Popover>
                <Button theme="matrix" onClick={create}>Create</Button>
                <Button theme="matrix" onClick={support}>Support</Button>
                <Button theme="matrix" onClick={blog}>Blog</Button>
            </div>
            <div className={styles["navbar-responsive-menu"]}>
                <Button theme="transparent">
                    <HambergerMenu size="32" color="var(--white-100)"/>
                </Button>
            </div>
        </nav>
    );
};

export default Nav;
